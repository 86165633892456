import React, {Fragment, useEffect, useState} from 'react'
import moment from 'moment'
import Header from '../../components/Header'
import TimeLine from './components/TimeLine'
import Contact from './components/Contact'
import Section from './components/Section'
import IconAddress from '../../style/images/about/about_icon_address.png'
import IconBusiness from '../../style/images/about/about_icon_business.png'
import IconJob from '../../style/images/about/about_icon_job.png'
import IconBossfrom from '../../style/images/about/pic_boss.png'
import CustomButton from '../../components/CustomButton'
import ContactForm from '../../components/ContactForm'
import {Modal, useModal} from '../../components/ModalPage'
import api, {handleResponse} from '../../api'

const BOSS_LINK = 'https://www.zhipin.com/gongsi/ed9dfcae2bfe78741nxy096_EA~~.html?ka=search_rcmd_company_ed9dfcae2bfe78741nxy096_EA~~_custompage'

const contactList = [
  {
    icon: IconAddress,
    title: '联系方式',
    content: (
      <Fragment>
        <div className='contact-content-detail'>
          柳州总部
          <br />
          桂中大道南端2号阳光壹佰城市广场25栋5-1号
        </div>
        <div className='contact-content-detail'>
          南宁分部
          <br />
          青秀区金洲路33号广西人才大厦708室
        </div>
      </Fragment>
    )
  },
  {
    icon: IconBusiness,
    title: '业务咨询',
    content: (
      <Fragment>
        <div className='contact-content-detail'>
          联系邮箱：i@dtchain.io
        </div>
      </Fragment>
    )
  },
  {
    icon: IconJob,
    title: '招聘方式',
    content: (
      <Fragment>
        <div className='contact-content-detail'>
          邮箱投递：hr@dtchain.io
        </div>
        <div className='contact-content-detail'>
        招聘链接：<a href={BOSS_LINK} target="_blank" rel="noopener noreferrer"><img src={IconBossfrom}/></a>
        </div>
      </Fragment>
    )
  },
]


const AboutDescription = () => <div className='description'>
  柳州市蓝海数链科技有限公司坐落在山清水秀的广西壮族自治区，是一家初创型高科技型企业，公司专注于区块链技术的研究与应用，致力于构建“区块链技术+产业服务”完整生态圈。利用区块链技术整合产业上下游供应链关系，打造依托新技术的企业一站式服务平台。为政府和企业提供优质的区块链技术解决方案与商业咨询服务。
  <br />
  <br />
  {/*公司目前为房地产开发、工业物流、快消零售业等行业提供供应链金融解决方案，协助平台集团企业优化供应链，提高资金使用率，传递企业信用，帮助产业供应链条末端企业解决融资难题。*/}
</div>

const ContactList = ({list}) => {
  return (
    <div className='contact-list'>
      {
        list.map((item, index) => (
          <Contact key={index} icon={item.icon} title={item.title} content={item.content} />
        ))
      }
    </div>
  )
}


const AboutUsPage = () => {
  const [visible, switchModal] = useModal(false)
  const [course, setCourse] = useState([])

  useEffect(() => {
    (async () => {
      const response = await api.getCourseRecords()
      handleResponse({
        response: response,
        resolve: data => {
          setCourse(data.map(item => {
            return {
              date: moment(item['time']).format('YYYY-MM'),
              content: item['content'],
              url: item['url']
            }
          }))
        }
      })
    })()
  }, [])

  return (
    <Fragment>
      <Header />
      <div className="banner about_us_banner">
        <div className="banner-title">链接价值 传递信用</div>
        <div className="banner-button">
          <CustomButton value="联系我们" size="large" onClick={() => switchModal(true)}/>
        </div>
      </div>

      <div className="about_us">
        <Section title='公司简介' Component={<AboutDescription />}/>
        <Section title='发展历程' Component={<TimeLine data={course}/>}/>
        <Section title='联系方式' Component={<ContactList list={contactList}/>}/>
      </div>
      <Modal visible={visible} hide={() => switchModal(false)} destroyOnClose={true}>
        <ContactForm onSubmit={() => switchModal(false)}/>
      </Modal>
    </Fragment>
  )
}

export default AboutUsPage
