import React, {Fragment} from 'react'
import Header from '../../components/Header'
import Banner from './components/Banner'
import BlockInfo from './components/BlockInfo'
import Production from './components/Production'
import News from './components/News'
// import Partner from './components/Partner'
import ContactForm from '../../components/ContactForm'
import {Modal, useModal} from '../../components/ModalPage'

function Home() {
  const [visible, switchModal] = useModal(false)

  return (
    <Fragment>
      <Header />
      <div className="home">
        <Banner setVisible={() => switchModal(true)} />
        <BlockInfo />
        <Production />
        <News />
        {/* <Partner /> */}
      </div>
      <Modal visible={visible} hide={() => switchModal(false)} destroyOnClose={true}>
        <ContactForm onSubmit={() => switchModal(false)}/>
      </Modal>
    </Fragment>
  )
}

export default Home
