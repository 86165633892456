import React, {useEffect} from 'react'
import {BrowserRouter as Router, Route, Switch, useLocation} from 'react-router-dom'
import Footer from './components/Footer'
import Home from './pages/home/index'
import AboutUs from './pages/about_us/about_us_page'
import News from './pages/news/news_page'
import NewsDetail from './pages/news/news_detail_page'
import Financial from './pages/product/financial'
import Assets from './pages/product/assets'
import Block from './pages/product/block'
import Transfer from './pages/product/transfer'
import Technology from './pages/technology/technology'
import NotFound from './pages/not_found'


function ScrollToTop({...props}) {
  const location = useLocation()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])

  return props.children
}

function App() {
  return (
    <div className='App'>
      <Router>
        <ScrollToTop>
          <Switch>
            <Route exact path='/' component={Home} />
            <Route exact path='/about' component={AboutUs} />
            <Route exact path='/news' component={News} />
            <Route exact path='/news/:news_id' component={NewsDetail} />
            <Route exact path='/product/financial' component={Financial} />
            <Route exact path='/product/assets' component={Assets} />
            <Route exact path='/product/block' component={Block} />
            <Route exact path='/product/transfer' component={Transfer} />
            <Route exact path='/technology' component={Technology} />
            <Route component={NotFound}/>
          </Switch>
        </ScrollToTop>
        <Footer />
      </Router>
    </div>
  )
}

export default App
