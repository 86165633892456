import React from 'react'
import {Layout} from "antd"
import Header from './components/Header'
import LoginForm from './components/LoginForm'
const {Content} = Layout

const LoginPage = () => {
  return (
    <Layout style={{height: "100%"}}>
      <Header />
      <Content>
        <div className="login">
          <div className="background">
            <div className="illustration"/>
          </div>
          <div className="login-content">
            <div className="login-content-detail">
              <div className="login-content-title">蓝海数链</div>
              <div className="login-content-title">官方网站管理后台</div>
              <div className="decorator" />
              <LoginForm />
            </div>
          </div>
        </div>
      </Content>
    </Layout>
  )
}

export default LoginPage
