import React, {Fragment} from 'react'
import Header from '../../components/Header'
import CSSTransitionByScroll from '../../components/CSSTransitionByScroll'
import icon_privacy from '../../style/images/product/transfer/icon_privacy.png'
import icon_credible from '../../style/images/product/transfer/icon_credible.png'
import icon_data from '../../style/images/product/transfer/icon_data.png'
import pic_business from '../../style/images/product/transfer/pic_business.png'
import icon_authorization from '../../style/images/product/transfer/icon_authorization.png'
import icon_decentralized from '../../style/images/product/transfer/icon_decentralized.png'
import icon_homomorphism from '../../style/images/product/transfer/icon_homomorphism.png'
import icon_mixing from '../../style/images/product/transfer/icon_mixing.png'
import {ProductPlan, ProductBusiness, ProductSafe} from './components/product'
    
const Banner = () => (
  <div className="product-banner" style={{backgroundColor: '#002D71'}}>
    <div className="banner-body">
      <div className="content">
        <CSSTransitionByScroll>
          <div className="title">数据存证交换解决方案</div>
        </CSSTransitionByScroll>
        <CSSTransitionByScroll delay={100}>
          <div className="description">将隐私数据进行多重加密后在进行区块链存证，保障数据可信度，再对存证数据进行多维权限控制，能够保证在可控范围内做到隐私数据的交换及共享，提升数据共享效率及安全性。</div>
        </CSSTransitionByScroll>
      </div>
      <CSSTransitionByScroll>
        <div className="pic_szczjh"/>
      </CSSTransitionByScroll>
    </div>
  </div>
)

const Transfer = () => {
  const planData = [
    {
      icon: icon_privacy,
      title: '隐私保障',
      des: '采用数字证书与同态加密技术，保障数据隐私安全'
    },
    {
      icon: icon_credible,
      title: '可信存储',
      des: '数据加密上传，数据分布式存储在区块链网络中'
    },
    {
      icon: icon_data,
      title: '数据共享',
      des: '权限控制，数据需求方在获得授权后才可获得访问权限'
    },
  ]

  const safeData = [
    {
      icon: icon_mixing,
      title: '混合部署',
      des: '采用终端私有化服务器和云端区块链混合部署⽅式，终端系统通过本地的秘钥与数字证书加密处理，再写⼊云端的区块链⽹络中。'
    },
    {
      icon: icon_decentralized,
      title: '去中心化',
      des: '采用去中心化的数据共享网络，数据拥有方进行数据存储，各节点备份加密数据，系统抗攻击能力强。'
    },
    {
      icon: icon_homomorphism,
      title: '同态加密',
      des: '数据处理（验证）者⽆法得知数据原⽂但仍旧可以进⾏处理，并可验证处理结果。'
    },
    {
      icon: icon_authorization,
      title: '授权解密',
      des: '仅在数据拥有方主动授权的情况下，第三⽅才能读取和查看平台上的数据。'
    },
  ]

  return (
    <Fragment>
      <Header theme="light"/>
      <div className="product">
        <Banner />
        
        <ProductPlan planData={planData} />
      
        <ProductBusiness tabsPane={[<img key={1} src={pic_business} />]} />
      
        <ProductSafe safeData={safeData} />
      </div>
    </Fragment>
  )
}

export default Transfer