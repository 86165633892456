import React from 'react'

const Title = ({icon = null, title = 'title', extra, ...props}) => (
  <div className='title-component' {...props}>
    <div>
      {icon && <div className='title-component-icon'>{icon}</div>}
      <span className="text">{title}</span>
    </div>
    <div>
      {extra}
    </div>
  </div>
)

export default Title