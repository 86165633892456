import {combineReducers} from 'redux'
import * as types from '../constants/ActionTypes'
import createReducerWithState from './reducerTemplate/createReducerWithState'

function createFilteredReducer(reducerFunction, type, reducerPredicate = action => action) {
  return (state, action) => {
    const isInitializationCall = state === undefined
    const shouldRunWrappedReducer = reducerPredicate(action) || isInitializationCall
    return shouldRunWrappedReducer ? reducerFunction(state, type, action) : state
  }
}

const rootReducer = combineReducers({
  newsRecords: createFilteredReducer(createReducerWithState, types.NEWS_RECORDS),
  admin: combineReducers(
    {
      newsRecords: createFilteredReducer(createReducerWithState, types.ADMIN_NEWS_RECORDS),
      advisoryRecords: createFilteredReducer(createReducerWithState, types.ADMIN_ADVISORY_RECORDS),
      courseRecords: createFilteredReducer(createReducerWithState, types.ADMIN_COURSE_RECORDS),
    }
  ),
})

export default rootReducer
