import React, {Fragment, useEffect, useState} from 'react'
import {Link, useParams} from 'react-router-dom'
import Header from '../../components/Header'
import * as lodash from 'lodash'
import api from '../../api'
import {utils} from 'dtchain-fe'
import {Spin} from 'antd'
import pic_news_record_empty from '../../style/images/news/pic_news_record_empty.png'

const NewsDetailPage = () => {
  const params = useParams()
  const [newsDetail, setNewsDetail] = useState({})
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState('')

  useEffect(() => {
    getData()
  }, [params['news_id']])

  const getData = () => {
    setLoading(true)
    api.getNewsDetail({code: params['news_id']})
      .then(res => {
        if(res.data.code === 0){
          setNewsDetail(res.data.data)
        }else {
          setMessage(res.data.message)
        }
        setLoading(false)
      })
      .catch(() => setLoading(false))
  }

  const handleClick = (e, disable) => {
    if(disable) {
      e.preventDefault()
    }
  }


  return (
    <Fragment>
      <Header />
      <div className="banner news_banner">
        <div className="banner-title">新闻动态</div>
      </div>
      <Spin spinning={loading}>
        <div className='news-detail'>
          <div className='news-detail-container'>
            {
              message 
                ? <div className='record-empty'>
                  <img src={pic_news_record_empty}/>
                  <div className='text'>{message}</div>
                </div> 
                : <Fragment>
                  <h1 className='news-detail-title'>{newsDetail['title']}</h1>
                  <div className='news-detail-date'>{newsDetail['created'] ? '发布：'+ utils.formatTime(newsDetail['created'], true) : ''}</div>
                  <div className='news-detail-content' dangerouslySetInnerHTML={{__html: newsDetail['content']}}/>
                  <div className='news-detail-others'>
                    <Link
                      className='news-detail-others-pre' 
                      onClick={e => handleClick(e, !lodash.get(newsDetail, 'previous.code'))} 
                      to={`/news/${lodash.get(newsDetail, 'previous.code')}`}>
                        上一篇：{lodash.get(newsDetail, 'previous.title', '沒有啦！')}
                    </Link>
                    <Link 
                      className='news-detail-others-next news-detail-others-active' 
                      onClick={e => handleClick(e, !lodash.get(newsDetail, 'next.code'))}  
                      to={`/news/${lodash.get(newsDetail, 'next.code')}`}>
                        下一篇：{lodash.get(newsDetail, 'next.title', '沒有啦！')}
                    </Link>
                  </div>
                </Fragment>
            }
          </div>
        </div>
      </Spin>
    </Fragment>
  )
}

export default NewsDetailPage