import React from 'react'
import {Menu} from 'antd'
import {ProfileOutlined, ScheduleOutlined, SettingOutlined} from '@ant-design/icons'
import {Link, withRouter} from 'react-router-dom'
import pathToRegexp from 'path-to-regexp'
const SubMenu = Menu.SubMenu

const MenuData = [
  {name: '新闻管理', icon: <ProfileOutlined />, path: '/admin/news'},
  {name: '回执管理', icon: <ScheduleOutlined />, path: '/admin/advisory'},
  {name: '大事记管理', icon: <SettingOutlined />, path: '/admin/course'},
]

export class SiderBar extends React.Component {
  state = {
    collapsed: true,
    openKeys: [],
    keys: [],
    menuData: []
  }

  // 根据菜单的路径生成menu key
  getFlatMenuKeys(menuData) {
    let keys = []
    menuData.forEach(item => {
      keys.push(item.path)
      if (item.children) {
        keys = keys.concat(this.getFlatMenuKeys(item.children))
      }
    })
    return keys
  }

  // 查看路径是否匹配
  getMenuMatches = (flatMenuKeys, path) =>
    flatMenuKeys.filter(item => {
      if (item) {
        return pathToRegexp(item).test(path)
      }
      return false
    });

  // 将'/asset/url' 变为 ['/asset', '/asset/url'] 的数组
  urlToList(url='') {
    const urllist = url.split('/').filter(i => i)
    return urllist.map((urlItem, index) => `/${urllist.slice(0, index + 1).join('/')}`)
  }

  // 返回能够匹配pathname的menu key的数组
  getSelectedMenuKeys = pathname => {
    const flatMenuKeys = this.state.keys
    return this.urlToList(pathname).map(itemPath => this.getMenuMatches(flatMenuKeys, itemPath).pop())
  };

  // 根据menuData生成菜单项
  getNavMenuItems = menuData => {
    if (!menuData) {
      return []
    }
    return menuData
      .filter(item => item.name && !item.hideInMenu)
      .map(item => this.getSubMenuOrItem(item))
      .filter(item => item)
  };

  // 生成次级菜单或菜单项
  getSubMenuOrItem = (item) => {
    if (item.children && !item.hideChildrenInMenu && item.children.some(child => child.name)) {
      const {name} = item
      return (
        <SubMenu
          title={
            item.icon ? (
              <span>
                {item.icon}
                <span>{name}</span>
              </span>
            ) : (
              name
            )
          }
          key={item.path}
        >
          {this.getNavMenuItems(item.children)}
        </SubMenu>
      )
    }
    return <Menu.Item key={item.path} >{this.getMenuItemPath(item)}</Menu.Item>
  };

  conversionPath = path => {
    if (path && path.indexOf('http') === 0) {
      return path
    }
    return `/${path || ''}`.replace(/\/+/g, '/')
  };

  // 生成菜单项
  getMenuItemPath = (item) => {
    const {name, icon} = item
    const itemPath = this.conversionPath(item.path)
    return (
      <Link
        to={itemPath}
      >
        <span>
          {icon}
          <span>{name}</span>
        </span>
      </Link>
    )
  }

  // 传入key是否属于菜单项
  isMainMenu = key => {
    return this.state.menuData.some(item => {
      if (key) {
        return item.key === key || item.path === key
      }
      return false
    })
  };

  handleOpenChange = openKeys => {
    const moreThanOne = openKeys.filter(openKey => this.isMainMenu(openKey)).length > 1
    this.setState({
      openKeys: moreThanOne ? [openKeys.pop()] : [...openKeys],
    })
  };

  componentDidMount() {
    // const menuData = this.getMenu(MenuData)
    // userType !== 1 && userType !== 2 && this.getPending()
    const menuData = MenuData
    this.setState({
      keys: this.getFlatMenuKeys(menuData),
      menuData
    })
  }

  render() {
    const {pathname} = this.props.location
    const selectedKeys = this.getSelectedMenuKeys(pathname)
    const {openKeys, menuData} = this.state
    const openKey = openKeys.length === 0 ? [...selectedKeys] : openKeys
    return (
      <Menu
        onClick={this.handleClick}
        selectedKeys={selectedKeys}
        mode="inline"
        theme="light"
        openKeys={openKey}
        onOpenChange={this.handleOpenChange}
        style={{paddingLeft: '8px'}}
      >
        {this.getNavMenuItems(menuData)}
      </Menu>
    )
  }
}

export default withRouter(SiderBar)
