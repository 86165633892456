import React from 'react'
import {Link} from 'react-router-dom'
// import icon_one from '../../../style/images/home/icon_one.png'
import icon_two from '../../../style/images/home/icon_two.png'
import icon_three from '../../../style/images/home/icon_three.png'
import icon_four from '../../../style/images/home/icon_four.png'
import CSSTransitionByScroll from '../../../components/CSSTransitionByScroll'

const data = [
  // {
  //   icon: icon_one,
  //   title: '供应链金融',
  //   info: '核心企业信用可沿着可信贸易链路传递，解决企业融资难问题，改良供应链末端企业生存环境，稳固供应链生态。',
  //   list: ['盘活闲置资产', '全闭环管理', '企业信用放大', '穿透式溯源', '多种合作方式', '打造产业银行'],
  //   link: '/product/financial'
  // },
  {
    icon: icon_two,
    title: '数字资产',
    info: '将不同类型的资产数字化上链并发行后，转化为标准化、可自由流通的数字资产，打破交易壁垒，降低交易成本。',
    list: ['穿透式监管', '流通便捷'],
    link: '/product/assets'
  },
  {
    icon: icon_three,
    title: '时空区块链',
    info: '依托“北斗时空码”对产品全生命周期记录并上传至区块链，保证产品的唯一性、真实性，提高品牌溢价能力。',
    list: ['全流程溯源', '交叉验证机制', '易于品质把控', '降低监管成本', '防篡改', '防造假'],
    link: '/product/block'
  },
  {
    icon: icon_four,
    title: '数据存证交换',
    info: '多节点保证数据的可信存储，保证数据未经篡改，能够在保证隐私安全的基础下跨机构共享可信数据。',
    list: ['数据安全共享', '多方信用背书', '隐私保护'],
    link: '/product/transfer'
  },
]

const Production = () => (
  <div id="production" className="production">
    <div className="production-box container">
      <CSSTransitionByScroll id="production">
        <div className="title">产品与服务</div>
      </CSSTransitionByScroll>
      <div className="production-body">
        {
          data.map((i, index) => (
            <CSSTransitionByScroll key={index} id="production" delay={100 * index}>
              <div className="item">
                <div className="icon">
                  <img src={i.icon} alt={i.title}/>
                </div>
                <div className="title">{i.title}</div>
                <div className="content">
                  <div className="info">{i.info}</div>

                  <div className="extra">
                    <div className="list">
                      {
                        i.list.map((_i, _index) => <div key={_index}>{_i}</div>)
                      }
                    </div>
                  </div>
                </div>
                <Link to={i.link}><div className="button">查看详情</div></Link>
              </div>
            </CSSTransitionByScroll>
          ))
        }
      </div>
    </div>
  </div>
)

export default Production