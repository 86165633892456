import React from 'react'
import {Button, Popconfirm, message} from 'antd'
import * as lodash from 'lodash'
import {useLocation} from 'react-router-dom'
import api from '../../../api'
import {store} from '../../../index'
import * as actions from '../../../actions/admin_course'

const CourseAction = ({data, onEdit}) => {
  const location = useLocation()

  const handleDelete = id => {
    api.deleteCourse(id)
      .then(res => {
        if(res.data.code === 0) {
          message.success('删除成功')
          store.dispatch(actions.getCourseRecords({page: lodash.get(location, 'state.page', 1), loadType: false}))
        }
      })
  }

  return (
    <div className='admin-table-item-actions'>
      <Button type='link' onClick={onEdit}>编辑</Button>
      <Popconfirm
        title='确认删除该大事记吗？'
        okText='确认'
        cancelText='取消'
        onConfirm={() => handleDelete(data['id'])}
      >
        <Button type='link' danger>删除</Button>
      </Popconfirm>
    </div>
 
  )
}

export default CourseAction