import React, {useState} from 'react'
import {Table} from 'dtchain-fe'
import moment from 'moment'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import * as Actions from '../../../actions/admin_course'
import CourseAction from './CourseAction'
import CourseForm from './CourseForm'

const Course = ({...props}) => {

  const {actions, courseRecords} = props
  const {total_records, page_size, isFetching} = courseRecords
  const [form, setForm] = useState({visible: false, data: {}})

  const columns = [
    {
      title: '时间',
      dataIndex: 'time',
      key: 'time',
      width: 200,
      render: text => moment(text).format('YYYY-MM')
    }, {
      title: '内容',
      dataIndex: 'content',
      key: 'content',
      render: text => text || '--'
    }, {
      title: '跳转URL',
      dataIndex: 'url',
      key: 'url',
      width: 400,
      render: text => text || '--'
    }, {
      title: '操作',
      dataIndex: 'level',
      key: 'level',
      align: 'center',
      width: 200,
      render: (text, record) => <CourseAction data={record} onEdit={() => setForm({visible: true, data: {...record}})}/>
    },
  ]

  return (
    <div className='admin-course-table'>
      <Table 
        columns={columns}
        data={courseRecords}
        loading={isFetching}
        actions={actions['getCourseRecords']}
        pageInfo={{total_records, page_size}}
        hasShadow={false}
      />
      <CourseForm visible={form['visible']} initData={form['data']} onCancel={() => setForm({visible: false, data: {}})}/>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    courseRecords: state.admin.courseRecords
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(Actions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Course)