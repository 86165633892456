import * as types from "../constants/ActionTypes"
import api from "../api"

export const getNewsRecords = (loadType, page=1) => {
  const params = {
    page_no: page,
    page_size: 15
  }
  return {
    type: types.ADMIN_NEWS_RECORDS,
    promise: api.getAdminNewsRecords(params),
    loadType
  }
}
