import React, {useState, Fragment, useEffect} from 'react'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import {useHistory, useLocation} from 'react-router-dom'
import Carousel, {Modal, ModalGateway} from 'react-images'
import * as Actions from '../../../actions/admin_news'
import TableComponent from '../../../components/TableComponent'
import NewsAction from '../components/NewsAction'
import {utils} from 'dtchain-fe'

const getColumns = (onDelete, handleImageClick, currentPage) => [
  {
    title: '标题',
    key: 'title',
    dataIndex: 'title',
    render: text => <span className='admin-table-item'>{text || '--'}</span>
  },
  {
    title: '封面图片',
    key: 'cover_picture',
    dataIndex: 'cover_picture',
    render: text => <span className='admin-table-item'><img className='admin-news-cover' src={text} onClick={() => handleImageClick(text)}/></span>
  },
  {
    title: '发布时间',
    key: 'created',
    dataIndex: 'created',
    render: text => <span className='admin-table-item'>{utils.formatTime(text, true, '-', true)}</span>
  },
  {
    title: '操作',
    render: (text, record) => (
      <span className='admin-table-item admin-news-actions'>
        <NewsAction data={record} onDelete={onDelete} currentPage={currentPage}/>
      </span>
    )
  },
]

const NewsList = ({...props}) => {
  const {newsRecordsInfo, actions} = props
  const location = useLocation()
  const history = useHistory()

  const locationState = location.state || {}
  
  const [carousel, setCarousel] = useState({modalIsOpen: false, images: []})
  const [page, setPage] = useState(locationState['page'] || 1)

  useEffect(() => {
    setLocationInfo()
    getData(!!location.state)
  }, [page])


  const getData = loadType => {
    if (!location.state || !newsRecordsInfo.data[page] || !loadType) {
      actions.getNewsRecords(loadType, page)
    }
  }

  const setLocationInfo = () => {
    const obj = {page}
    history.replace(location.pathname, {...locationState, ...obj})
  }

  const handleImageClick = url => {
    setCarousel({
      modalIsOpen: true,
      images: [{source: url}]
    })
  }


  return (
    <Fragment>
      <TableComponent 
        columns={getColumns(getData, handleImageClick, page)}
        pageInfo={{
          current_page: page,
          total_records: newsRecordsInfo.total_records,
          page_size: newsRecordsInfo.page_size
        }}
        data={newsRecordsInfo.data[page] || []}
        onPaginationChange={currentPage => setPage(currentPage)}
        loading={newsRecordsInfo.isFetching}
      />
      <ModalGateway>
        {carousel.modalIsOpen ? (
          <Modal onClose={() => setCarousel({modalIsOpen: false, images: []})}>
            <Carousel views={carousel.images} />
          </Modal>
        ) : null}
      </ModalGateway>
    </Fragment>
  )
}

const mapStateToProps = state => {
  return {
    newsRecordsInfo: state.admin.newsRecords
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(Actions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewsList)
