import React, {Component, Fragment} from 'react'
import {withRouter} from 'react-router-dom'
import Header from '../../components/Header'
import data_save from '../../style/images/technology/data_save.png'
import node from '../../style/images/technology/node.png'
import alliance from '../../style/images/technology/alliance.png'
import algorithm from '../../style/images/technology/algorithm.png'
import chain from '../../style/images/technology/chain.png'
import contract from '../../style/images/technology/contract.png'

const data = [
  {
    id: 'avalible',
    img: data_save,
    title: '数据存储优化措施',
    des: '账本数据可兼容使用各大云平台的块储存产品；状态和历史数据可使用包括LevelDB等原生方案及其他主流数据库，加强数据健壮性，不易丢失'
  },
  {
    img: node,
    title: '节点故障数据恢复机制',
    des: '当节点突发故障，可即时检测到故障发生，数据恢复机制将自动启动，能够保证业务不中断',
    bg_gray: true
  },
  {
    id: 'save',
    img: alliance,
    title: '联盟节点共治',
    des: '分布在联盟机构的联盟节点遵循统一合约，接受联盟内其他节点监督'
  },
  {
    id: 'extends',
    img: algorithm,
    title: '多种共识算法',
    des: '支持PBFT、DPOS、Kafka、BFT-Raft多种共识算法，在不同需求场景下应用以达效率最优',
    bg_gray: true
  },
  {
    img: chain,
    title: '多链支持',
    des: '侧链可针对业务需求进行部署，各侧链数据隔离，仅侧链关联的相关企业可进行访问'
  },
  {
    img: contract,
    title: '低门槛智能合约',
    des: '支持多种主流开发语言进行智能合约编写，如Java、Python、GO、Solidity等',
    bg_gray: true
  },
]

class Technology extends Component {
  constructor(props) {
    super(props)
    this.state = {
      anchor: '#avalible',
      fix: false,
      nav_top: 0,
      save_top: 0,
      extends_top: 0,
    }

  }
  handleScroll = () => {
    const {nav_top, save_top, extends_top} = this.state
    const scroll = window.scrollY || window.pageYOffset

    this.setState({
      fix: scroll > nav_top,
      anchor: scroll > extends_top ? '#extends' : scroll > save_top ? '#save' : '#avalible'
    })
  }
  componentDidMount() {
    this.setState({
      nav_top: document.getElementById('nav-bar').offsetTop,
      save_top: document.getElementById('save').offsetTop - 1,
      extends_top: document.getElementById('extends').offsetTop - 1
    })
    window.addEventListener('scroll', this.handleScroll)
  }
  componentDidUpdate(prevProps) {
    const {location} = this.props
    if(prevProps.location.hash !== location.hash) {
      this.setState({anchor: location.hash})
    }
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }
  render() {
    const {anchor, fix} = this.state

    return (
      <Fragment>
        <Header theme="light" style={{position: 'relative'}}/>
        <div className="technology">
          <div className="banner technology-banner">
            <div className="banner-title">蓝海数链区块链底层平台</div>
            <div className="banner-info">蓝海数链通过多项创新技术，打造了具备高可用、易拓展、强隐私保护等特性的区块链底层平台，运用区块链底层技术模块化、接口化、插件化封装后架设在云服务器上，形成若干个网络节点，可支撑丰富的场景应用。</div>
          </div>
          <div id="nav-bar" className={`technology-nav ${fix ? 'technology-nav-fixed' : ''}`}>
            <div className="container nav-box">
              <div className={`nav-item ${anchor === '#avalible' ? 'active' : ''}`}><a href="#avalible">高可用</a></div>
              <div className={`nav-item ${anchor === '#save' ? 'active' : ''}`}><a href="#save">强安全</a></div>
              <div className={`nav-item ${anchor === '#extends' ? 'active' : ''}`}><a href="#extends">易拓展</a></div>
            </div>
          </div>
          <div style={fix ? {height: document.getElementById('nav-bar').offsetHeight + 'px'} : {}}/>
          <div className="technology-box">
            {
              data.map((i, index) => (
                <div key={index} className={i.bg_gray ? "bg-gray" : ''}>
                  <div id={i.id} className="anchor"/>
                  <div className="item container">
                    {
                      i.bg_gray ? [
                        <div key="pic" className="pic">
                          <img src={i.img} />
                        </div>,
                        <div key="content" className="content">
                          <div className="title">{i.title}</div>
                          <div className="des">{i.des}</div>
                        </div>
                      ] : [
                        <div key="content" className="content">
                          <div className="title">{i.title}</div>
                          <div className="des">{i.des}</div>
                        </div>,
                        <div key="key" className="pic">
                          <img src={i.img} />
                        </div>
                      ]
                    }
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      </Fragment>
    )
  }
}

export default withRouter(Technology)