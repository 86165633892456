import React, {Fragment} from 'react'
import Header from '../components/Header'

const NotFound = () => {
  return (
    <Fragment>
      <Header theme="light"/>
      <div className="not-found"/>
    </Fragment>
  )
}

export default NotFound