import {fetchData} from "./resource"

export default {
  login: function(data) {
    return fetchData(`post`, `/user/login`, {data, transform: true})
  },
  // 后台新闻
  getAdminNewsRecords: function(params) {
    return fetchData(`get`, `/news/records`, {params})
  },
  deleteAdminNews: function(id) {
    return fetchData(`delete`, `/news/${id}/delete`)
  },
  getUploadToken: function () {
    return fetchData(`get`, `/storage/upload-token`)
  },
  getPicPreview: function (params) {
    return fetchData(`get`, `/storage/preview-files`, {params})
  },
  publishNews: function(data) {
    return fetchData(`post`, `/news/publish`, {data, transform: true})
  },
  //后台回执
  getAdminAdvisoryRecords: function(params) {
    return fetchData(`get`, `/advisory/records`, {params})
  },
  addAdvisory: function(data) {
    return fetchData(`post`, `/advisory/add`, {data, transform: true})
  },
  handleAdvisory: function(data) {
    return fetchData(`post`, `/advisory/${data['id']}/deal`, {data, transform: true})
  },

  //后台大事记
  getAdminCourseRecords: function(params) {
    return fetchData(`get`, `/course/records`, {params})
  },
  publishCourse: function(data) {
    return fetchData(`post`, `/course/publish`, {data, transform: true})
  },
  deleteCourse: function(id){
    return fetchData(`delete`, `/course/${id}/delete`)
  },

  // 前台新闻
  getNewsRecords: function(params) {
    return fetchData(`get`, `/news/news`, {params})
  },
  getNewsDetail: function(params) {
    return fetchData(`get`, `/news/detail`, {params})
  },

  //前台 大事记
  getCourseRecords: function(params) {
    return fetchData(`get`, `/course/events`, {params})
  },


}

export const handleResponse = ({response, resolve=() => {}, reject = () => {}}) => {
  if(response.data.code === 0) {
    resolve(response.data.data)
  } else {
    reject()
  }
}

