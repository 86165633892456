import React, {useState} from 'react'

const Tabs = ({calssName = 'custom-tab', ...props}) => {
  const {tabsTitle = [], activeTab = 0, children} = props
  const [tab, setTab] = useState(activeTab)
  return (
    <div className={calssName}>
      <div className="switch">
        {tabsTitle.map((i, index) => 
          <div key={index}
            className={`switch-title ${tab === index ? 'title-active' : ''}`}
            onClick={() => setTab(index)}
          >{i}</div>
        )}
      </div>
      <div className="tab-box">
        {children.length <= 1 ? children : children.map((i, index) => (
          <div key={index} className={`item ${tab === index ? 'item-active' : ''}`}>{i}</div>
        ))}
      </div>
    </div>
  )
}

export const CustomTab = props => <Tabs calssName = 'custom-tab' {...props}/>

export const CustomSideTab = props => <Tabs calssName = 'customSide-tab' {...props}/>