import React from 'react'
import {Layout} from 'antd'
import {BrowserRouter as Router, Route, Switch, Redirect} from 'react-router-dom'
import Sider from './components/Sider'
import Header from './components/Header'
import AdminNews from './admin_news_page'
import AdminNewsEdit from './admin_news_edit_page'
import AdminAdvisory from './admin_advisory_page'
import AdminCourse from './admin_course_page'

const {Content} = Layout

const AdminPage = () => (
  <Router>
    <Layout style={{height: "100%"}}>
      <Header />
      <Layout style={{flex: 1}}>
        <Sider/>
        <Content style={{padding: '32px'}}>
          <Switch>
            <Route exact path="/admin" render={() => <Redirect to="/admin/news" push />} />
            <Route exact path='/admin/news' component={AdminNews} />
            <Route exact path='/admin/news/edit' component={AdminNewsEdit} />
            <Route exact path='/admin/news/edit/:id' component={AdminNewsEdit} />
            <Route exact path='/admin/advisory' component={AdminAdvisory} />
            <Route exact path='/admin/course' component={AdminCourse} />
          </Switch>
        </Content>
      </Layout>
    </Layout>
  </Router>
)

export default AdminPage