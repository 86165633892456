import React, {useState} from 'react'
import IconProgress from '../../../style/images/about/about_progress.png'

const TimeLineItem = ({data, isLaster}) => {
  const [isHover, setIsHover] = useState(false)
  return (
    <div
      className='about-timeline'
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <div className='about-timeline-date'>{data.date}</div>
      <div className='about-timeline-status'>
        <div className='status-tail-horizontal' />
        {!isLaster && <div className='status-tail-vertical' />}
        {
          isHover ? 
            <div className='status-circle-hover status-circle' /> :
            <img src={IconProgress} className='status-circle'/>
        }
      </div>
      {
        data.url ? 
          <a
            className={`about-timeline-content ${isHover ? `about-timeline-content-hover` : ``}`}
            href={data.url}
            target='_blank'
            rel="noopener noreferrer"
          >
            {data.content}
          </a> :
          <div
            className={`about-timeline-content ${isHover ? `about-timeline-content-hover` : ``}`}
          >
            {data.content}
          </div>
      }
    </div>
  )
}

const TimeLine = ({data}) => (
  <div>
    {
      data.map((item, index) => <TimeLineItem data={item} key={index} isLaster={index === data.length - 1}/>)
    }
  </div>
)

export default TimeLine