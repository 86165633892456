import React, {Fragment} from 'react'
import Header from '../../components/Header'
import CSSTransitionByScroll from '../../components/CSSTransitionByScroll'
import icon_assets from '../../style/images/product/assets/icon_assets.png'
import icon_transfer from '../../style/images/product/assets/icon_transfer.png'
import icon_low_cost from '../../style/images/product/assets/icon_low_cost.png'
import icon_supervision from '../../style/images/product/assets/icon_supervision.png'
import pic_business from '../../style/images/product/assets/pic_business.png'
import {ProductPlan, ProductBusiness, ProductWorth} from './components/product'
    
const Banner = () => (
  <div className="product-banner" style={{backgroundColor: '#002E73'}}>
    <div className="banner-body">
      <div className="content">
        <CSSTransitionByScroll>
          <div className="title">区块链+数字资产解决方案</div>
        </CSSTransitionByScroll>
        <CSSTransitionByScroll delay={100}>
          <div className="description">将各类资产数字化后登记、发行并记录至链上，形成标准化的数字资产后可在区块链上自由流转，极大地提升数字资产流通效率，以达到“多方发行、自由流通”的效果。</div>
        </CSSTransitionByScroll>
      </div>
      <CSSTransitionByScroll>
        <div className="pic_szzc"/>
      </CSSTransitionByScroll>
    </div>
  </div>
)

const Assets = () => {
  const planData = [
    {
      icon: icon_assets,
      title: '资产真实锚定',
      des: '流通资产与链上资产相互锚定，实时可追踪'
    },
    {
      icon: icon_transfer,
      title: '流通场景灵活',
      des: '标准化数字资产可打通线上线下场景，自由流通'
    },
    {
      icon: icon_low_cost,
      title: '交易低成本',
      des: '减少了第三方收取的中间费用，低成本流通资产'
    },
    {
      icon: icon_supervision,
      title: '可靠易监管',
      des: '链上资产可被监管方实时监控，全程可追溯'
    },
  ]

  const planExtra = {
    title: '解决传统资产服务中几大痛点', 
    list: [
      '资产流通依赖第三方渠道',
      '资产不透明，难追踪',
      '资产交易流通成本高',
    ]
  }

  const worthData = [
    {
      title: '资产发行方',
      list: [
        '能够将任意类型的资产转换为标准化的数字资产进行流通。',
        '降低了发行费用，无需借助第三方机构。'
      ]
    },
    {
      title: '资产交易方',
      list: [
        '获得可信来源的资产。',
        '流通不再局限于某一平台用户体系，采用统一标准的用户均可任意流通。'
      ]
    },
    {
      title: '监管机构',
      list: [
        '资产来源、流通路径清晰，有据可查，数据可信。',
        '大大减少传统业务中大量的人力物力成本。'
      ]
    },
  ]

  return (
    <Fragment>
      <Header theme="light"/>
      <div className="product">
        <Banner />
        
        <ProductPlan planData={planData} planExtra={planExtra}/>
      
        <ProductBusiness tabsPane={[<img key={1} src={pic_business} />]} />
      
        <ProductWorth worthData={worthData}/>
      </div>
    </Fragment>
  )
}

export default Assets