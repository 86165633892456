import React from 'react'
import CSSTransitionByScroll from '../../../components/CSSTransitionByScroll'
import {CustomTab, CustomSideTab} from '../../../components/CustomTab'

export const ProductPlan = ({planData, planExtra}) => (
  <div id="product-plan" className="product-plan container">
    <CSSTransitionByScroll id="product-plan">
      <div className="title">方案亮点</div>
    </CSSTransitionByScroll>
    <div className="plan-box">
      {
        planData.map((i, index) => 
          <CSSTransitionByScroll key={index} id="product-plan" delay={400 * (index + 1)}>
            <div className="plan-box-item">
              <div className="plan-icon"><img src={i.icon}/></div>
              <div className="plan-title">{i.title}</div>
              <div className="plan-des">{i.des}</div>
            </div>
          </CSSTransitionByScroll>
        )
      }
    </div>
    {
      planExtra && <div className="plan-extra clearfix">
        <CSSTransitionByScroll id="product-plan">
          <div className="plan-extra-title">{planExtra.title}：</div>
        </CSSTransitionByScroll>
        <ul className="plan-extra-body">
          {
            planExtra.list.map((i, index) => 
              <CSSTransitionByScroll key={index} id="product-plan" delay={1600 + 400 * (index + 1)}>
                <li key={index}>{i}</li>
              </CSSTransitionByScroll>
            )
          }
        </ul>
      </div>
    }
  </div>
)

export const ProductBusiness = ({tabsTitle, tabsPane}) => (
  <div id="product-business" className="product-business container">
    <CSSTransitionByScroll id="product-business">
      <div className="title">业务模式</div>
    </CSSTransitionByScroll>
    <div className="business-box">
      <CustomTab activeTab={0} tabsTitle={tabsTitle}>
        {
          tabsPane.map((i, index) => 
            <CSSTransitionByScroll key={index} id="product-business">
              {i}
            </CSSTransitionByScroll>
          )
        }
      </CustomTab>
    </div>
  </div>
)

export const ProductWorth = ({worthData}) => (
  <div id="product-worth" className="product-worth">
    <div className="container">
      <CSSTransitionByScroll id="product-worth">
        <div className="title">价值体现</div>
      </CSSTransitionByScroll>
      <div className="worth-box">
        {
          worthData.map((i, index) => (
            <CSSTransitionByScroll key={index} id="product-worth" delay={300 * (index + 1)}>
              <div className="worth-box-item">
                <div className="item-title">{i.title}</div>
                {i.list.map((item, _index) => <div key={_index} className="info">{item}</div>)}
              </div>
            </CSSTransitionByScroll>
          ))
        }
      </div>
    </div>
  </div>
)

export const ProductCase = ({caseData, tabsTitle}) => (
  <div id="product-case" className="product-case">
    <div className="container">
      <CSSTransitionByScroll id="product-case">
        <div className="title">案例展示</div>
      </CSSTransitionByScroll>
      <div className="case-box">
        <CustomSideTab activeTab={0} tabsTitle={tabsTitle}>
          {caseData.map((i, index) => 
            <div key={index} className="case-item">
              <CSSTransitionByScroll id="product-case">
                <div className="case-item-title">产品介绍</div>
              </CSSTransitionByScroll>
              <CSSTransitionByScroll id="product-case" delay={300}>
                <div className="case-item-info">{i.introduce}</div>
              </CSSTransitionByScroll>
              {
                i.cooperation && [
                  <CSSTransitionByScroll key={0} id="product-case" delay={600}>
                    <div className="case-item-title">合作方展示</div>
                  </CSSTransitionByScroll>,
                  <CSSTransitionByScroll key={1} id="product-case" delay={900}>
                    <div className="case-item-info">
                      {i.cooperation}
                    </div>
                  </CSSTransitionByScroll>
                ]
              }
              
            </div>
          )}
        </CustomSideTab>
      </div>
    </div>
  </div>
)

export const ProductAccess = ({accessData}) => (
  <div id="product-access" className="product-access container">
    <CSSTransitionByScroll id="product-access">
      <div className="title">接入方式</div>
    </CSSTransitionByScroll>
    <div className="access-box" style={accessData.length === 2 ? {justifyContent: 'space-around'} : {}}>
      {
        accessData.map((i, index) => 
          <CSSTransitionByScroll key={index} id="product-access" delay={300 * (index + 1)}>
            <div className="access-box-item">
              <div className="access-icon"><img src={i.icon}/></div>
              <div className="access-title">{i.title}</div>
              <div className="access-title">{i.des}</div>
            </div>
          </CSSTransitionByScroll>
        )
      }
    </div>
  </div>
)

export const ProductSafe = ({safeData}) => (
  <div id="product-safe" className="container">
    <div className="product-safe">
      <CSSTransitionByScroll id="product-safe">
        <div className="title">数据安全保障</div>
      </CSSTransitionByScroll>
      <div className="safe-box">
        {
          safeData.map((i, index) => 
            <CSSTransitionByScroll key={index} id="product-safe" delay={300 * (index + 1)}>
              <div key={index} className="safe-box-item">
                <div className="box-item">
                  <div className="safe-pic"><img src={i.icon}/></div>
                  <div className="safe-content">
                    <div className="safe-title">{i.title}</div>
                    <div className="safe-des">{i.des}</div>
                  </div>
                </div>
              </div>
            </CSSTransitionByScroll>
          )
        }
      </div>
    </div>
  </div>
)

