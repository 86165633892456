import React from 'react'
import RCPagination from 'rc-pagination'

const Pagination = ({onPageChange, pageSize=15, currentPage=1, total=1}) => {

  const itemRender = (current, type, element) => {
    if(type === 'prev') {
      return <a className='dtchain-pagination-customize-item'>&lt;上一页</a>
    }
    if(type === 'next') {
      return <a className='dtchain-pagination-customize-item'>下一页&gt; </a>
    }

    return element
  }

  const getIcons = () => {
    const prevIcon = <a>&lt;上一页</a>
    const nextIcon = <a>下一页&gt; </a>

    const jumpPrevIcon = <a>...</a>
    const jumpNextIcon = <a>...</a>

    return {
      prevIcon,
      nextIcon,
      jumpNextIcon,
      jumpPrevIcon
    }
  }

  return (
    <RCPagination
      prefixCls='dtchain-pagination'
      total={total}
      pageSize={pageSize}
      current={currentPage}
      onChange={onPageChange} 
      {...getIcons()}
      itemRender={itemRender}/>  
  )
}

export default Pagination