import React from 'react'
import {Menu, Dropdown} from 'antd'
import {DownOutlined} from '@ant-design/icons'
import {logout} from '../../../utils'

const MyMenu = (
  <Menu>
    <Menu.Item onClick={() => logout()}>
      <div className='user-info-menu'>退出登录</div>
    </Menu.Item>
  </Menu>
)

const UserInfo = () => {
  
  return (
    <div className='user-info'>
      <Dropdown overlay={MyMenu}>
        <div className='user-info-name'>
          admin
          <DownOutlined />
        </div>
      </Dropdown>
    </div>
  )
}

export default UserInfo