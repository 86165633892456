import React from 'react'
import {Button} from 'antd'
import {withRouter} from 'react-router-dom'
import Title from '../../components/Title'
import NewsList from './components/NewsList'

const AdminNewsPage = ({...props}) => (
  <div className='admin-news'>
    <Title title='新闻列表' extra={<Button type='primary' onClick={() => { props.history.push(`/admin/news/edit`) }}>新增</Button>}/>
    <NewsList />
  </div>
)

export default withRouter(AdminNewsPage)