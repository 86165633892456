import React from 'react'
import Title from '../../components/Title'
import Course from './components/Course'
import CourseAdd from './components/CourseAdd'

const AdminCoursePage = () => {

  return (
    <div className='admin-course'>
      <Title title='大事记管理' extra={<CourseAdd />}/>
      <Course />
    </div>
  )
}

export default AdminCoursePage