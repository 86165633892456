import React, {useEffect} from 'react'
import {Link} from 'react-router-dom'
import CSSTransitionByScroll from '../../../components/CSSTransitionByScroll'
import CustomButton from '../../../components/CustomButton'
import * as Actions from '../../../actions/news_records'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import {utils} from 'dtchain-fe'

const News = ({...props}) => {
  const {newsRecordsInfo: {data}, actions} = props

  useEffect(() => {
    getData(!!location.state)
  }, [])

  const getData = loadType => {
    if (!location.state || !data[1] || !loadType) {
      actions.getNewsRecords(loadType, 1)
    }
  }

  const _data = data[1] || []

  return (
    <div id="home-news">
      <div className="home-news container">
        <CSSTransitionByScroll id="home-news">
          <div className="home-news-title">最新动态</div>
        </CSSTransitionByScroll>
        <div className="home-news-body">
          {
            _data.slice(0, 3).map((i, index) => 
              <CSSTransitionByScroll key={index} id="home-news" delay={200 * index}>
                <div className="item">
                  <Link to={`/news/${i.code}`}>
                    <img src={i.cover_picture} alt="pic_news"/>
                    <div className="content">
                      <div className="title">{i.title}</div>
                      <div className="sub-title">{utils.formatTime(i.created, true, false, false, '.')}</div>
                    </div>
                  </Link>
                </div>
              </CSSTransitionByScroll>
            )
          }
        </div>
        <CSSTransitionByScroll id="home-news">
          <div className="home-news-button">
            <Link to="/news"><CustomButton value="查看更多" size="large"/></Link>
          </div>
        </CSSTransitionByScroll>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    newsRecordsInfo: state.newsRecords
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(Actions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(News)