import React from 'react'
import {Layout} from 'antd'
import {MenuFoldOutlined, MenuUnfoldOutlined} from '@ant-design/icons'
import SiderBar from './SiderBar'

const ASider = Layout.Sider

class Sider extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      collapsed: false,
    }
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange() {
    this.setState({
      collapsed: !this.state.collapsed
    })
    this.triggerResizeEvent()
  }

  triggerResizeEvent() {
    const event = document.createEvent('HTMLEvents')
    event.initEvent('resize', true, false)
    window.dispatchEvent(event)
  }

  render() {
    const {isMobile} = this.props
    const {collapsed} = this.state
    return <React.Fragment>
      <ASider key={1}
        theme="light"
        collapsible
        trigger={null}
        breakpoint="xl"
        collapsed={collapsed}
        onCollapse={ collapse => {
          this.setState({
            collapsed: collapse
          })
          this.triggerResizeEvent()
        }}
      >
        <SiderBar isMobile={isMobile} collapsed={this.state.collapsed}/>
      </ASider>
      <span key={2} id={"side-button"} onClick={this.handleChange}>
        {
          collapsed ?
            <MenuUnfoldOutlined /> : 
            <MenuFoldOutlined />
        }
      </span>
    </React.Fragment>
  }
}

export default Sider