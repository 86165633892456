import React, {Component} from 'react'
import {Link, withRouter} from 'react-router-dom'

class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      fixed: false,
    }
  }
  handleScroll = () => {
    const scroll = window.scrollY || window.pageYOffset
    this.setState({
      fixed: scroll > 0
    })
  }
  componentDidMount() {
    this.props.theme === 'scroll' && document.addEventListener('scroll', this.handleScroll)
  }
  componentWillUnmount() {
    this.props.theme === 'scroll' && document.removeEventListener('scroll', this.handleScroll)
  }

  render() {
    const {location: {pathname}, theme, style} = this.props

    const routes = [
      {pathname: '/product', label: '产品与服务', children: [
        // {pathname: '/product/financial', label: '供应链金融'},
        {pathname: '/product/assets', label: '数字资产'},
        {pathname: '/product/block', label: '时空区块链'},
        {pathname: '/product/transfer', label: '数据存证交换'}
      ]},
      {pathname: '/technology', label: '核心技术'},
      {pathname: '/news', label: '新闻动态'},
      {pathname: '/about', label: '关于我们'},
    ]

    const _theme = theme === 'scroll' ? (this.state.fixed ? "header-light" : "") : "header-light"

    return (
      <header className={_theme} style={style || {}}>
        <div className="header-body">
          <div className="logo"/>
          <div className="top-nav">
            <div className="top-nav-item"><Link to="/"><span className={pathname === '/' ? "active" : ''}>首页</span></Link></div>
            {
              routes.map((i, index) => (
                <div key={index} className="top-nav-item">
                  <Link to={i.children ? pathname : i.pathname}>
                    <span className={pathname.includes(i.pathname) ? "active" : ''}>{i.label}</span>
                  </Link>
                  {i.children && i.children.length > 0 &&
                    <div className="extra">
                      {
                        i.children.map((item, itemIndex) =>
                          <div key={itemIndex} className="item">
                            <Link to={item.pathname}>{item.label}</Link>
                          </div>
                        )
                      }
                    </div>
                  }
                </div>
              ))
            }
          </div>
        </div>
      </header>
    )
  }
}

Header.defaultProps = {
  theme: 'scroll'
}

export default withRouter(Header)
