import React, {useState} from 'react'
import {Form, Input, Button, Spin, message} from 'antd'
import {withRouter} from 'react-router-dom'
import cookies from 'react-cookies'
import api from '../../../api'

const LoginForm = ({...props}) => {
  const {history} = props
  const [loading, setLoading] = useState(false)

  const onFinish = fieldsValue => {
    setLoading(true)
    api.login(fieldsValue)
      .then(res => {
        if(res.data.code === 0){
          cookies.save('website-token', res.data.data, {path: '/'})
          message.success('登录成功')
          setTimeout(() => {
            setLoading(false)
            history.push('/admin/news')
          }, 1000)
        }else {
          setLoading(false)
        }
      })
      .catch(() => setLoading(false))
  }

  return (
    <Spin spinning={loading}>
      <Form onFinish={onFinish} className="login-form">
        <Form.Item name='cellphone' rules={[{required: true, message: '请输入手机号'}]} >
          <Input placeholder="手机号" size="large"/>
        </Form.Item>
        <Form.Item name='password' rules={ [{required: true, message: '请输入密码'}]}>
          <Input type="password" placeholder="密码" size="large"/>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType='submit' className="login-form-button" size="large" style={{width: '100%'}}>
            登录
          </Button>
        </Form.Item>
      </Form>
    </Spin>
  )
}

export default withRouter(LoginForm)