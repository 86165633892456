import React from 'react'
import cookies from "react-cookies"
import {BrowserRouter as Router, Route, Switch, Redirect} from 'react-router-dom'
import App from './App'
import Admin from './pages/admin/admin_page'
import Login from './pages/admin/login_page'

const isLogin = () => {
  return cookies.load("website-token")
}

export default () => (
  <Router>
    <Switch>
      <Route exact path='/admin/login' render={
        props => isLogin() ? 
          <Redirect to={{pathname: "/admin/news", state: {from: props.location}}} /> : 
          <Login {...props}/>
      } />
      <Route path='/admin' render={
        props => isLogin() ?
          <Admin {...props}/> : 
          <Redirect to={{pathname: '/admin/login', state: {from: props.location}}} />
      } />
      <Route path='/' component={App} />
    </Switch>
  </Router>
)
