import * as types from "../constants/ActionTypes"
import api from "../api"

export const getCourseRecords = ({page=1, loadType=false}) => {
  const params = {
    page_no: page,
    page_size: 15 
  }
  return {
    type: types.ADMIN_COURSE_RECORDS,
    promise: api.getAdminCourseRecords(params),
    loadType
  }
}