import React, {Fragment, useState} from 'react'
import {Button} from 'antd'
import CourseForm from './CourseForm'

const CourseAdd = () => {
  const [visible, setVisible] = useState(false)
  return (
    <Fragment>
      <Button type='primary' onClick={() => setVisible(true)}>新增</Button>
      <CourseForm visible={visible} isNew={true} onCancel={() => setVisible(false)} />
    </Fragment>
  )
}

export default CourseAdd