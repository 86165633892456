import React from 'react'
import {Modal, Form, Descriptions, Select, message} from 'antd'
import api from '../../../api'

const AdvisoryModel = ({onHandleAdvisory, switchModal, data, visible=true}) => {
  const [form] = Form.useForm()

  const selectOptions = [
    {label: '请选择', value: -1},
    {label: '待处理', value: 0},
    {label: '需跟进', value: 1},
    {label: '已完成', value: 2},
  ]

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields()
      const params = {...values}
      params['id'] = data['id']
      api.handleAdvisory(params)
        .then(res => {
          if(res.data.code === 0){
            message.success('处理回执成功')
            switchModal()
            onHandleAdvisory()
          }
        })
    } catch (error) {
    }
  }

  return (
    <Modal
      title='回执处理'
      visible={visible}
      onOk={handleSubmit}
      okText='确定'
      cancelText='取消'
      onCancel={switchModal}
    >
      <Descriptions column={2}>
        <Descriptions.Item label="姓名">{data['name']}</Descriptions.Item>
        <Descriptions.Item label="联系电话">{data['cellphone']}</Descriptions.Item>
        <Descriptions.Item label="邮箱">{data['email']}</Descriptions.Item>
        <Descriptions.Item label="职位">{data['position']}</Descriptions.Item>
        <Descriptions.Item label="公司名称" span={2}>{data['company_name']}</Descriptions.Item>
        <Descriptions.Item label="咨询内容" span={2}>{data['content']}</Descriptions.Item>
      </Descriptions>
      <Form layout='vertical' initialValues={{status: data['deal_status'] || -1}} form={form}>
        <Form.Item
          label='处理结果:'
          name='status'
          rules={[({getFieldValue}) => ({
            validator(rule, value) {
              if (!value || getFieldValue('status') !== -1) {
                return Promise.resolve()
              }
              return Promise.reject('请选择处理结果')
            },
          })]}
        >
          <Select>
            {
              selectOptions.map(item => (
                <Select.Option key={item.value} value={item.value}>{item.label}</Select.Option>
              ))
            }
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default AdvisoryModel