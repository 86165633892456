import * as types from "../constants/ActionTypes"
import api from "../api"

export const getAdvisoryRecords = (loadType, page=1) => {
  const params = {
    page_no: page,
    page_size: 15
  }
  return {
    type: types.ADMIN_ADVISORY_RECORDS,
    promise: api.getAdminAdvisoryRecords(params),
    loadType
  }
}