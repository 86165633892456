import React, {useState, useEffect} from 'react'
import IconClose from '../style/images/about/icon_delete.png'

const useModal = initVisible => {
  const [visible, setVisible] = useState(initVisible || false)

  const switchModal = modalVisible => {
    setVisible(modalVisible)
  }

  return [
    visible,
    switchModal
  ]
}

const Modal = ({visible, hide, destroyOnClose=false, ...props}) => {
  useEffect(() => {
    listenMove(visible)
  }, [visible])

  const touchMove = e => {
    e.preventDefault()//阻止默认的处理方式(阻止下拉滑动的效果)
  }

  const listenMove = modalVisible => {
    if(modalVisible) {
      window.addEventListener('touchmove', touchMove, {passive: false})
    } else {
      window.removeEventListener('touchmove', touchMove)
    }
  }

  const renderChildren = (destroy, modalVisible) => {
    if(destroy && !modalVisible) return null
    return props.children
  }

  return (
    <div className="modal-page" style={{backgroundColor: `rgba(0,0,0,0.25)`, display: visible ? 'flex' : 'none'}}>
      <div className='modal-page-container'>
        <div className='modal-page-container-close' onClick={() => hide()}><img src={IconClose} /></div>
        {renderChildren(destroyOnClose, visible)}
        <style>
          {`
            body {
              overflow: ${visible ? 'hidden' : 'auto'} ;
            }
          `}
        </style>
      </div>
    </div>
  )
} 

export {
  Modal,
  useModal
}