import React from 'react'
import {Layout} from 'antd'
import {useLocation} from 'react-router-dom'
import UserInfo from './UserInfo'
import logo from '../../../style/images/admin/logo.png'

const Header = () => {
  const location = useLocation()
  return (
    <Layout.Header className='admin-header'>
      <div className="logo-wrap">
        <img src={logo} className="logo" alt="logo"/>
        <span className="logo-title">官方网站管理后台</span>
      </div>
      {!/\/admin\/login/.test(location.pathname) && <UserInfo />}
    </Layout.Header>
  )
}

export default Header