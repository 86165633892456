import React from 'react'

const Contact = ({icon, title='联系方式', content}) => (
  <div className='contact'>
    <div className='contact-way'>
      <img src={icon} />
      <span className='contact-way-title'>{title}</span>
    </div>
    <div className='contact-content'>
      {content}
    </div>
  </div>
)

export default Contact