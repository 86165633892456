import React, {useEffect, useRef, useState, Fragment} from 'react'
import {useParams, useHistory, useLocation} from 'react-router-dom'
import {Form, Button, Input, message, Spin, Popconfirm} from 'antd'
import {UploadOutlined} from '@ant-design/icons'
import * as lodash from 'lodash'
import {Upload, utils} from 'dtchain-fe'
import api from '../../api'
import Gallery from '../../components/Gallery'
import Editor from './components/Editor'
import {store} from '../../index'
import * as actions from '../../actions/admin_news'

const titleRules = [
  {required: true, message: '请输入标题'},
  {max: 50, message: '标题最多输入50字'}
]

const coverPictureRules = [{required: true, message: '请上传封面'}]

const summaryRules = [
  {required: true, message: '请输入摘要'},
  {max: 200, message: '摘要最多输入200字'}
]

const contentRules = [{required: true, message: '请输入新闻内容'}]


const AdminNewsEditPage = () => {
  const params = useParams()
  const history = useHistory()
  const location = useLocation()
  const isEdit = useRef(false)
  const [loading, setLoading] = useState(false)
  const [uploadInfo, setUploadInfo] = useState({token: '', domain: ''})
  const [coverPicture, setCoverPicture] = useState([])
  const [galleryCoverPicture, setGalleryCoverPicture] = useState([])
  const [form] = Form.useForm()

  useEffect(() => {
    getModel()
    getUploadInfo()
    initForm(location)
  }, [])

  const getUploadInfo = () => {
    api.getUploadToken()
      .then(res => {
        if(res.data.code === 0){
          setUploadInfo({
            token: res.data.data.token,
            domain: res.data.data.domain
          }) 
        }
      })
  }

  const getUploadFileName = () => {
    let filename = {
      name: `${utils.getTimestamp()}${utils.randomWord(6)}`,
      key: 'website/news'
    }
    return filename
  }

  const getModel = () => {
    isEdit.current = params['id'] !== undefined
  }

  const setFiles = (key, file) => {
    api.getPicPreview({file_keys: file.response.key}).then(res => {
      if(res.data.code === 0) {
        const obj = {
          source: res.data.data[0].file_url,
        }
        setGalleryCoverPicture([obj])
        setCoverPicture([{
          file_key: file.response.key,
          file_name: file.name,
          seq_no: galleryCoverPicture.length + 1,
          uid: file.uid,
        }])
        form.setFieldsValue({cover_picture: file.response.key})
      }
    })
  }
  const handlesubmit = async () => {
    try {
      setLoading(true)
      const values = await form.validateFields()
      const data = {...values}
      isEdit.current && (data['id'] = params['id'])
      api.publishNews(data)
        .then(res => {
          if(res.data.code === 0) {
            message.success(`新闻${isEdit.current ? '编辑' : '发布'}成功`)
            store.dispatch(actions.getNewsRecords(false, lodash.get(location, 'state.currentPage', 1)))
            history.goBack()
          } else{
            setLoading(false)
          }
        })
        .catch(() => setLoading(false))
    } catch (error) {
      setLoading(false)
    }
  }

  const onEditorChange = value => {
    form.setFieldsValue({content: value})
  }

  const initForm = pageLocation => {
    if(isEdit.current) {
      const {state:{news}} = pageLocation
      form.setFieldsValue({
        title: news['title'],
        summary: news['summary'],
        content: news['content'],
        cover_picture: news['cover_picture_key']
      })
      setGalleryCoverPicture([{source: news['cover_picture']}])
    }
  }


  return (
    <div className='admin-news-edit'>
      <Spin spinning={loading}>
        <Form wrapperCol={{span: 23}} labelCol={{span: 1}} form={form} colon={false}>
          <Form.Item name='title' label='标题' rules={titleRules}>
            <Input />
          </Form.Item>
          <Form.Item name='cover_picture' label='封面' rules={coverPictureRules}>
            <Fragment>
              <Upload
                listType='picture'
                accept='image/*'
                fileList={coverPicture}
                token={uploadInfo.token}
                domain={uploadInfo.domain}
                filename={getUploadFileName}
                setFiles={(key, file) => setFiles(key, file)}
              >
                <Button icon={<UploadOutlined />}>点击上传</Button>
              </Upload>
              <Gallery images={galleryCoverPicture}/>
            </Fragment>
          </Form.Item>
          <Form.Item name='summary' label='摘要' rules={summaryRules}>
            <Input.TextArea rows={4} style={{'resize': 'none'}}/>
          </Form.Item>
          <Form.Item name='content' label='内容' rules={contentRules}>
            <div className="admin-news-edit-Editor">
              <Editor onContentChange={onEditorChange} initValue={lodash.get(location, 'state.news.content', null)}/>
            </div>
          </Form.Item>
        </Form>
        <div className='admin-news-edit-button'>
          <Popconfirm
            title="返回将不会保存您修改的内容，是否确认返回?"
            onConfirm={() => history.goBack()}
            okText="确认"
            cancelText="取消"
          >
            <Button>返回</Button>
          </Popconfirm>
          <Button type='primary' onClick={() => handlesubmit()}>确定</Button>
        </div>
      </Spin>
    </div>
  )
}

export default AdminNewsEditPage