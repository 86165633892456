import React, {Component, Fragment} from 'react' 
import {isIE} from '../utils/isIE'

export default class CSSTransitionByScroll extends Component {
  constructor(props) {
    super(props)
    this.state = {
      transitionName: `${props.className}-${isIE() === true ? 'after' : 'before'}`,
      offsetTop: 0,
      delay: props.delay || 0
    }
  }
  setName = () => {
    const {className} = this.props
    setTimeout(() => this.setState({transitionName: `${className}-after`}), this.state.delay)
  }
  handleScroll = () => {
    if(this.state.offsetTop < window.scrollY + window.innerHeight) {
      this.setName()
      this.removeEventListener()
    }
  }
  removeEventListener = () => {
    document.removeEventListener("scroll", this.handleScroll)
  }
  componentDidMount() {
    const {id} = this.props
    const top = document.getElementById(id).offsetTop + 200
    this.setState({offsetTop: top})
    document.addEventListener("scroll", this.handleScroll)

    if(top < window.scrollY + window.innerHeight) this.setName()
  }
  componentWillUnmount() {
    this.removeEventListener()
  }
  render() {
    const {children} = this.props
    const {transitionName} = this.state

    let _children = React.cloneElement(children, {className: `${children.props.className} ${transitionName}`})
    return (
      <Fragment>
        {_children}
        <style>
          {`
            .fadeInUp-before{
              opacity: 0;
              transform: translate(0, 100px);
            }
            
            .fadeInUp-after {
              opacity: 1;
              transform: none;
              transition: opacity 1000ms, transform 1000ms;
            }
          `}
        </style>
      </Fragment>
    )
  }
}

CSSTransitionByScroll.defaultProps = {
  id: "root",
  className: "fadeInUp"
}