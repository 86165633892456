import React, {forwardRef, useState} from 'react'
import {createForm} from 'rc-form'
import {message, Spin} from 'antd'
import Input from './Input'
import CustomButton from '../components/CustomButton'
import api from '../api/'

// eslint-disable-next-line no-unused-vars
const RefInput = forwardRef((props, ref) => <Input {...props} />)
// eslint-disable-next-line no-unused-vars
const RefTextArea = forwardRef((props, ref) => <Input.TextArea {...props} />)

const ContactForm = ({onSubmit, ...props}) => {
  const {getFieldDecorator, getFieldError} = props.form
  const [loading, setLoading] = useState(false)


  const submit = () => {
    props.form.validateFields((error, values) => {
      if(!error){
        const data = {...values}
        setLoading(true)
        api.addAdvisory(data)
          .then(res => {
            setLoading(false)
            if(res.data.code === 0){
              message.success('信息已经提交成功')
              onSubmit()
            }
          })
          .catch(() => setLoading(false))
      }
    })
  }

  return (
    <Spin spinning={loading}>
      <div className="contact-modal-title">联系我们</div>
      <div className="contact-modal-content">
        <div className='contact-modal-form'>
          <div className='contact-modal-form-item'>
            <div className='contact-modal-form-item-label-require'>
              您的姓名：
            </div>
            {getFieldDecorator(
              'name',
              {
                rules: [{
                  required: true,
                  message: '请输入您的姓名'
                }],
                initialValue: ''
              },)(<RefInput isErr={getFieldError('name') !== undefined}/>)
            }
            <div className='contact-modal-form-item-err-info'>
              {getFieldError('name')}
            </div>
          </div>

          <div className='contact-modal-form-item'>
            <div className='contact-modal-form-item-label-require'>
              联系电话：
            </div>
            {getFieldDecorator(
              'cellphone',
              {
                rules: [
                  {
                    required: true,
                    message: '请输入您的电话'
                  },
                  {
                    pattern: /^1(3|4|5|7|8)\d{9}$/,
                    message: '请输入正确的电话号码'
                  }
                ],
                initialValue: ''
              },)(<RefInput isErr={getFieldError('phone') !== undefined} />)
            }
            <div className='contact-modal-form-item-err-info'>
              {getFieldError('cellphone')}
            </div>
          </div>

          <div className='contact-modal-form-item'>
            <div className='contact-modal-form-item-label'>
              您的邮箱：
            </div>
            {getFieldDecorator(
              'email',
              {
                initialValue: '',
                rules:[
                  {
                    // eslint-disable-next-line no-useless-escape
                    pattern:  /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/,
                    message: '请输入正确的邮箱格式'
                  }
                ]
              })(<RefInput />)
            }
            <div className='contact-modal-form-item-err-info'>
              {getFieldError('email')}
            </div>
          </div>

          <div className='contact-modal-form-item'>
            <div className='contact-modal-form-item-label-require'>
              您的职位：
            </div>
            {getFieldDecorator(
              'position',
              {
                initialValue: '',
                rules: [{
                  required: true,
                  message: '请输入您的职位'
                }]
              },)(<RefInput isErr={getFieldError('job') !== undefined} />)
            }
            <div className='contact-modal-form-item-err-info'>
              {getFieldError('position')}
            </div>
          </div>

          <div className='contact-modal-form-item'>
            <div className='contact-modal-form-item-label-require'>
              公司名字：
            </div>
            {getFieldDecorator(
              'company_name',
              {
                initialValue: '',
                rules: [{
                  required: true,
                  message: '请输入您的公司'
                }]
              },)(<RefInput isErr={getFieldError('company') !== undefined}/>)
            }
            <div className='contact-modal-form-item-err-info'>
              {getFieldError('company_name')}
            </div>
          </div>

          <div className='contact-modal-form-item'>
            <div className='contact-modal-form-item-label'>
              咨询内容：
            </div>
            {getFieldDecorator(
              'content',
              {
                initialValue: '',
                rules: [
                  {
                    max: 200,
                    message: '最多输入200字'
                  }
                ]
              })(<RefTextArea placeholder='最多输入200字'/>)
            }
            <div className='contact-modal-form-item-err-info'>
              {getFieldError('content')}
            </div>
          </div>
        </div>
        <div className="contact-modal-content-button">
          <CustomButton value="提交" onClick={submit}/>
        </div>
      </div>
    </Spin>
  )
}

export default createForm()(ContactForm)