import React from 'react'
import {Link} from 'react-router-dom'
import {utils} from 'dtchain-fe'

const NewItem = ({newItem={}}) => {
  return (

    <div className='new-item'>
      <img className='new-item-img' src={newItem.cover_picture} />
      <div className='new-item-details'>
        <div className='new-item-details-title'>
          <Link to={`/news/${newItem['code']}`}>{newItem.title}</Link>
        </div>
        <p className='new-item-details-summary'>{newItem.summary}</p>
        <div className='new-item-details-date'>{`发布：${utils.formatTime(newItem.created, true)}`}</div>
      </div>
    </div>
  )

}

export default NewItem