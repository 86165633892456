import React, {Fragment, useState, useEffect} from 'react'
import Header from '../../components/Header'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import {useHistory, useLocation} from 'react-router-dom'
import * as Actions from '../../actions/news_records'
import NewItem from './components/NewItem'
import Pagination from '../../components/Pagination'
import {Spin} from 'antd'

const NewsList = ({list = []}) => {
  return <div className='news-list'>
    {
      list.map((item, index) => <NewItem newItem={item} key={index}/>)
    }
  </div>
}

const NewsPage = ({...props}) => {
  const {newsRecordsInfo, actions} = props
  const location = useLocation()
  const history = useHistory()
  const locationState = location.state || {}
  
  const [page, setPage] = useState(locationState['page'] || 1)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLocationInfo()
    getData(!!location.state)
  }, [page])

  useEffect(() => {
    switchLoading(newsRecordsInfo.isFetching)
  }, [newsRecordsInfo.isFetching])

  const getData = loadType => {
    if (!location.state || !newsRecordsInfo.data[page] || !loadType) {
      actions.getNewsRecords(loadType, page)
    }
  }

  const setLocationInfo = () => {
    const obj = {page}
    history.replace(location.pathname, {...locationState, ...obj})
  }

  const switchLoading = isFetching => {
    if(isFetching) {
      setLoading(true)
    } else {
      setLoading(false)
    }
  }


  return (
    <Fragment>
      <Header />
      <div className="banner news_banner">
        <div className="banner-title">新闻动态</div>
      </div>
      <div className='news'>
        <Spin spinning={loading}>
          <NewsList list={newsRecordsInfo.data[page] || []} />
          <div className='news-pagination'>
            <Pagination 
              total={newsRecordsInfo.total_records}
              pageSize={newsRecordsInfo.page_size}
              currentPage={page}
              onPageChange={currentPage => setPage(currentPage)}
            />
          </div>
        </Spin>
      </div>
    </Fragment>
  )
}

const mapStateToProps = state => {
  return {
    newsRecordsInfo: state.newsRecords
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(Actions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewsPage)