import React from 'react'
import CSSTransitionByScroll from '../../../components/CSSTransitionByScroll'

const BlockInfo = () => {
  const nodeData = [
    {delay: 500, className: 'left-top', derection: "fadeInRightBottom", side: 'isLeftSide', title: '多种共识机制', info: '支持PBFT、DPOS、Kafka、BFT-Raft、PoA等多种共识算法，应用在不同需求场景下以达效率最优'},
    {delay: 1000, className: 'right-top', derection: "fadeInLeftBottom", side: 'isRightSide', title: '低门槛智能合约', info: '支持多种主流开发语言进行智能合约的编写，如Java、Python、GO、Solidity等'},
    {delay: 1500, className: 'left-bottom', derection: "fadeInRightTop", side: 'isLeftSide', title: '应用插件化', info: '遵循插件化风格，根据不同场景进行模块配置、拔插，可支撑不同应用场景，增强可拓展性'},
    {delay: 2000, className: 'right-bottom', derection: "fadeInLeftTop", side: 'isRightSide', title: '隐私保护措施', info: '基于PKI的身份管理认证体系，通过链间隔离、国密算法加密等措施保障用户数据隐私'},
  ]
  return (
    <div id="block">
      <div className="block-info-bg-top"/>
      <div className="block-info">
        <div className="block-box container">
          <CSSTransitionByScroll id="block">
            <div className="title">区块链底层平台</div>
          </CSSTransitionByScroll>
          <div className="block-body">
            <div className="block-circle">
              <div className="block-center"/>
              <div className="bg-line">
                <div className="left">
                  <CSSTransitionByScroll id="block" delay={1000} className="fadeInRightBottom">
                    <div className="top"/>
                  </CSSTransitionByScroll>
                  <CSSTransitionByScroll id="block" delay={1000} className="fadeInRightTop">
                    <div className="bottom"/>
                  </CSSTransitionByScroll>
                </div>
                <div className="right">
                  <CSSTransitionByScroll id="block" delay={1000} className="fadeInLeftBottom">
                    <div className="top"/>
                  </CSSTransitionByScroll>
                  <CSSTransitionByScroll id="block" delay={1000} derection="fadeInLeftTop">
                    <div className="bottom"/>
                  </CSSTransitionByScroll>
                </div>
              </div>
              

              {
                nodeData.map((i, index) => {
                  return(
                    <CSSTransitionByScroll key={index} id="block" delay={i.delay} className={i.derection}>
                      <div className={`block-item ${i.className}`}>
                        <div className="line"/>
                        <div className="node"/>
                        <div className={`node-text ${i.side}`}>
                          <div className="node-text-title">{i.title}</div>
                          <div className="node-text-info">{i.info}</div>
                        </div>
                      </div>
                    </CSSTransitionByScroll>
                  )
                })
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BlockInfo