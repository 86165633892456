import React, {Fragment} from 'react'
import Header from '../../components/Header'
import CSSTransitionByScroll from '../../components/CSSTransitionByScroll'
import icon_brand from '../../style/images/product/block/icon_brand.png'
import icon_information from '../../style/images/product/block/icon_information.png'
import icon_product from '../../style/images/product/block/icon_product.png'
import pic_business from '../../style/images/product/block/pic_business.png'
import icon_api from '../../style/images/product/block/icon_api.png'
import icon_applets from '../../style/images/product/block/icon_applets.png'
import {
  ProductPlan, 
  ProductBusiness, 
  ProductWorth, 
  // ProductCase, 
  ProductAccess
} from './components/product'
    
const Banner = () => (
  <div className="product-banner">
    <div className="banner-body">
      <div className="content">
        <CSSTransitionByScroll>
          <div className="title">时空区块链溯源解决方案</div>
        </CSSTransitionByScroll>
        <CSSTransitionByScroll delay={100}>
          <div className="description">当前市场中各方受利益驱使，造假、乱报价等乱象频发，不断消磨消费者对品牌、平台及商家的信任，最终导致品牌价值受损甚至影响企业正常经营。本方案引入区块链+北斗时空码新防伪机制，采用区块链+大数据分析，提供从原材料至成品销售全流程管理追溯，打造高价值品牌。</div>
        </CSSTransitionByScroll>
      </div>
      <CSSTransitionByScroll>
        <div className="pic_skqkl"/>
      </CSSTransitionByScroll>
    </div>
  </div>
)

const Block = () => {
  const planData = [
    {
      icon: icon_brand,
      title: '提升品牌价值',
      des: '加强数据分析，维护品牌形象，提升企业核心竞争力'
    },
    {
      icon: icon_information,
      title: '信息追溯',
      des: '全流程上链，任意环节均可追溯，可快速定位问题环节'
    },
    {
      icon: icon_product,
      title: '产品防伪',
      des: '时空码+区块链交叉验证，确保记录真实无篡改'
    },
  ]

  const planExtra = {
    title: '解决传统溯源行业中几大痛点',
    list: [
      '信息不互通，管理效率低',
      '事前难预防，事后难追责',
      '消费者缺乏对品牌的信任',
    ]
  }

  const worthData = [
    {
      title: '供应链企业',
      list: [
        '信息透明化，利于提升企业品牌形象、声誉及价值。',
        '利于把控全链条风险，保障产品质量。',
        '有助企业科学化管理，提升生产效率。'
      ]
    },
    {
      title: '消费者',
      list: [
        '给与商品细节的知情权，来源可靠有保障，打消消费顾虑。'
      ]
    },
    {
      title: '监管方',
      list: [
        '提高质检水平，将安全质量隐患控制在最低范围内。',
        '端到端可追溯，便于管控整体供应链，明确主体责任。'
      ]
    },
  ]

  const accessData = [
    {
      icon: icon_api,
      title: 'API定制化开发'
    },
    {
      icon: icon_applets,
      title: '小程序定制开发',
    },
  ]

  // const caseData = [
  //   {
  //     introduce: '与北航苏州研究院的产学联合研究项目。',
  //     cooperation: <Fragment><img /><img /></Fragment>
  //   }
  // ]
  return (
    <Fragment>
      <Header theme="light"/>
      <div className="product">
        <Banner />
        
        <ProductPlan planData={planData} planExtra={planExtra}/>
      
        <ProductBusiness tabsPane={[<img key={1} src={pic_business} />]} />
      
        <ProductWorth worthData={worthData}/>

        {/* <ProductCase caseData={caseData} tabsTitle={['时空区块链溯源平台']}/> */}

        <ProductAccess accessData={accessData}/>
      </div>
    </Fragment>
  )
}

export default Block