import React from 'react'

const Input = ({style, isErr=false, ...props}) => (
  <input className={`dtchain-input ${isErr && 'dtchain-input-error'}`} style={style} {...props}/>
)

Input.TextArea = ({style, isErr=false, ...props}) => (
  <textarea className={`dtchain-input ${isErr && 'dtchain-input-error'}`} style={style} {...props}/>
)

export default Input