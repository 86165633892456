import React from 'react'

const CustomButton = ({value, size = 'normal', onClick}) => (
  <div className="custom-button">
    <div className={`button ${size}`} onClick={onClick}>
      {value}
    </div>
  </div>
)

export default CustomButton