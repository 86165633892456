import React from 'react'

const footerData = [
  {
    title: '快速入口',
    flex: 1,
    list: [
      // {label: '应收账款保理融资平台-企业', link: 'https://clique.yd.xfin.xyz/'},
      // {label: '应收账款保理融资平台-保理', link: 'https://fact.yd.xfin.xyz/'},
      {label: '区块链浏览器', link: 'https://explorer.dtchain.io/'},
    ]
  },
  // {
  //   title: '资源下载',
  //   flex: 1,
  //   list: [
  //     {label: '区块链供应链金融白皮书'}
  //   ]
  // },
  {
    title: '联系我们',
    flex: 2,
    list: [
      {label: '柳州市桂中大道南端2号阳光壹佰城市广场25栋5-1号'},
      {label: '南宁市青秀区金洲路33号广西人才大厦708室'},
      {label: '联系邮箱：i@dtchain.io'}
    ]
  },
]

const Footer = () => (
  <footer >
    <div className="footer-body">
      <div className="footer">
        <div className="footer-left">
          { footerData.map((item, itemIndex) =>
            <div key={itemIndex} className="footer-item" style={{flex: item.flex}}>
              <div className="footer-item-title">{item.title}</div>
              <div className="footer-item-content">
                {item.list.map((i, index) => <div key={index} className="content">
                  {i.link ? <a href={i.link} target="blank">{i.label}</a> : i.label}
                </div>)}
              </div>
            </div>
          )}
        </div>
        <div className="footer-right">
          <div className="qrcode"/>
          <div className="text">微信公众号：蓝海数链</div>
        </div>
      </div>
      <div className="footer-extra">
        <a href="http://www.beian.miit.gov.cn/" target="blank">
          Copyright © 2018-2020 柳州市蓝海数链科技有限公司 | 桂ICP备18003024号-4 | 桂网信备45020219116193390010号
        </a>
      </div>
    </div>
  </footer>
)

export default Footer