import React, {Component} from 'react'

export default class FadeInBanner extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showImgIndex: 0,
      style: {},
      auto: true
    }
  }

  clearInterval = () => clearInterval(this.timeId)

  onDotClick = (e) => {
    const id = Number(e.target.dataset.id)
    if(!isNaN(id) && id !== this.state.showImgIndex) {
      this.clearInterval()
      this.setState({auto: false, showImgIndex: id, style : {width : '100%'}})
    }
  }

  componentDidMount() {
    this.changeBanner()
    setTimeout(() => this.setState({style: {width: 0}}), 0)
  }

  componentWillUnmount() {
    this.clearInterval()
  }

  changeBanner = () => {
    const {duration, children} = this.props
    const number = children && children.length
    let t = setInterval(() => {
      const {showImgIndex} = this.state
      this.setState({showImgIndex: showImgIndex + 1 === number ? 0 : showImgIndex + 1})
    }, duration)
    this.timeId = t
  }

  render() {
    const {children, duration} = this.props
    const {showImgIndex, style, auto} = this.state

    if(!children || children.length === 0) return

    return (
      <div className="fade-in-banner">
        <ol className="banner-wrap">
          {children.length > 1 ? children.map((i, index) => index === showImgIndex && <li key={index} className={`banner-item`}>{i}</li>) : children}
        </ol>

        <div className="container">
          <div className="banner-dots">
            { 
              Array(children.length).fill('').map(
                (i, index) =>
                  <span key={index} className={`dot`} data-id={index} onClick={this.onDotClick}>
                    {
                      <span className={`active ${auto ? "active-transition" : ''}`}
                        style={showImgIndex === index ? style : {visibility: 'hidden'}}
                      />
                    }
                  </span>
              )
            }
          </div>
        </div>
        <style>
          {`
            .active-transition {
              transition: width ${duration}ms linear;
            }
          `}
        </style>
      </div>
    )
  }
}

FadeInBanner.defaultProps = {
  duration: 5000
}
