import React from 'react'
import {Button, Popconfirm, message} from 'antd'
import {withRouter} from 'react-router-dom'
import api from '../../../api/'

const NewsAction = ({data, onDelete, currentPage, ...props}) => {

  const handleDelete = id => {
    api.deleteAdminNews(id)
      .then(res => {
        if(res.data.code === 0) {
          message.success('删除成功')
          onDelete()
        }
      })
  }

  const handleEdit = id => {
    const {history} = props
    history.push(`/admin/news/edit/${id}`, {news: data, currentPage})
  }
  return (
    <div className='admin-table-item-actions'>
      <Button type='link' onClick={() => handleEdit(data['id'])}>编辑</Button>
      <Popconfirm
        title='确认删除该新闻吗？'
        okText='确认'
        cancelText='取消'
        onConfirm={() => handleDelete(data['id'])}
      >
        <Button type='link' danger>删除</Button>
      </Popconfirm>
    </div>

  )
}

export default withRouter(NewsAction)