import React from 'react'
import Title from '../../components/Title'
import AdvisoryRecords from './components/AdvisoryRecords'

const AdminAdvisoryPage = () => (
  <div className='admin-advisory'>
    <Title title='回执管理' />
    <AdvisoryRecords />
  </div>
)

export default AdminAdvisoryPage