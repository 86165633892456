import React, {Fragment, useState, useEffect} from 'react'
import {Button} from 'antd'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import {useLocation, useHistory} from 'react-router-dom'
import TableComponent from '../../../components/TableComponent'
import {utils} from 'dtchain-fe'
import AdvisoryModel from './AdvisoryModel'
import * as Actions from '../../../actions/admin_advisory'

const statusMap = {
  0: '待处理',
  1: '需跟进',
  2: '已完成'
}

const getColumns = (handleAdvisory) => [
  {
    title: '姓名',
    key: 'name',
    dataIndex: 'name',
    render: text => <span className='admin-table-item'>{text || '--'}</span>
  },
  {
    title: '联系电话',
    key: 'cellphone',
    dataIndex: 'cellphone',
    render: text => <span className='admin-table-item'>{text || '--'}</span>
  },
  {
    title: '邮箱',
    key: 'email',
    dataIndex: 'email',
    render: text => <span className='admin-table-item'>{text || '--'}</span>
  },
  {
    title: '职位',
    key: 'position',
    dataIndex: 'position',
    render: text => <span className='admin-table-item'>{text || '--'}</span>
  },
  {
    title: '公司名称',
    key: 'company_name',
    dataIndex: 'company_name',
    render: text => <span className='admin-table-item'>{text || '--'}</span>
  },
  {
    title: '反馈时间',
    key: 'created',
    dataIndex: 'created',
    render: text => <span className='admin-table-item'>{utils.formatTime(text, true, '-', true)}</span>
  },
  {
    title: '状态',
    key: 'deal_status',
    dataIndex: 'deal_status',
    render: text => <span className='admin-table-item'>{statusMap[text] || '--'}</span>
  },
  {
    title: '操作',
    render: (text, record) => (
      <span className='admin-table-item'>
        <div className='admin-table-item-actions'>
          <Button type='link' onClick={() => handleAdvisory(record)}>处理</Button>
        </div>
      </span>
    )
  },
]

const AdminAdvisory = ({...props}) => {
  const {advisoryRecordsInfo, actions} = props
  const location = useLocation()
  const history = useHistory()

  const locationState = location.state || {}

  const [visible, setVisible] = useState(false)
  const [modalAdvisory, setModalAdvisory] = useState({})
  const [page, setPage] = useState(locationState['page'] || 1)

  useEffect(() => {
    setLocationInfo()
    getData(!!location.state)
  }, [page])

  const switchModal = () => setVisible(!visible)

  const handleAdvisory = advisory => {
    setModalAdvisory(advisory)
    switchModal()
  }

  const getData = loadType => {
    if (!location.state || !advisoryRecordsInfo.data[page] || !loadType) {
      actions.getAdvisoryRecords(true, page)
    }
  }

  const setLocationInfo = () => {
    const obj = {page}
    history.replace(location.pathname, {...locationState, ...obj})
  }

  return (
    <Fragment>
      <TableComponent 
        columns={getColumns(handleAdvisory)}
        pageInfo={{
          current_page: page,
          total_records: advisoryRecordsInfo.total_records,
          page_size: advisoryRecordsInfo.page_size
        }}
        loading={advisoryRecordsInfo.isFetching}
        data={advisoryRecordsInfo.data[page] || []}
        onPaginationChange={currentPage => setPage(currentPage)}
      />
      <AdvisoryModel visible={visible} switchModal={switchModal} data={modalAdvisory} onHandleAdvisory={getData}/>
    </Fragment>
  )
}

const mapStateToProps = state => {
  return {
    advisoryRecordsInfo: state.admin.advisoryRecords
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(Actions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminAdvisory)