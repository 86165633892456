import React, {useRef, useState, useEffect} from 'react'
import BraftEditor from 'braft-editor'
import {ContentUtils} from 'braft-utils'
import 'braft-editor/dist/index.css'
import {Upload, utils} from 'dtchain-fe'
import api from '../../../api'

const buildPreviewHtml = editorState => {
  return `
    <!Doctype html>
    <html>
      <head>
        <title>Preview Content</title>
        <style>
        html, body {
          font-family: PingFang SC,Microsoft YaHei;
          font-size: @font-size-normal;
          line-height: 1;
        }
        html, body, div, span, applet, object, iframe,
        h1, h2, h3, h4, h5, h6, p, blockquote, pre,
        a, abbr, acronym, address, big, cite, code,
        del, dfn, em, img, ins, kbd, q, s, samp,
        small, strike, strong, sub, sup, tt, var,
        b, u, i, center,
        dl, dt, dd, ol, ul, li,
        fieldset, form, label, legend,
        table, caption, tbody, tfoot, thead, tr, th, td,
        article, aside, canvas, details, embed,
        figure, figcaption, footer, header, hgroup,
        menu, nav, output, ruby, section, summary,
        time, mark, audio, video {
          margin: 0;
          padding: 0;
          border: 0;
          vertical-align: baseline;
        }
        /* HTML5 display-role reset for older browsers */
        article, aside, details, figcaption, figure,
        footer, header, hgroup, menu, nav, section {
          display: block;
        }
        body {
          line-height: 1;
        }
        ol, ul {
          list-style: none;
        }
        blockquote, q {
          quotes: none;
        }
        blockquote:before, blockquote:after,
        q:before, q:after {
          content: '';
          content: none;
        }
        table {
          border-collapse: collapse;
          border-spacing: 0;
        }
        a{
          text-decoration: none;
        }
        input {
          border: 0;
        }
          .container{
            box-sizing: border-box;
            width: 1000px;
            max-width: 100%;
            min-height: 100%;
            margin: 0 auto;
            padding: 30px 20px;
            overflow: hidden;
            background-color: #fff;
            border-right: solid 1px #eee;
            border-left: solid 1px #eee;
          }
          .container img,
          .container audio,
          .container video{
            max-width: 100%;
            height: auto;
          }
          .container p{
            white-space: pre-wrap;
            min-height: 1em;
          }
          .container pre{
            padding: 15px;
            background-color: #f1f1f1;
            border-radius: 5px;
          }
          .container blockquote{
            margin: 0;
            padding: 15px;
            background-color: #f1f1f1;
            border-left: 3px solid #d1d1d1;
          }
        </style>
      </head>
      <body>
        <div class="container">${editorState.toHTML()}</div>
      </body>
    </html>
  `
}

const Editor = ({onContentChange, initValue}) => {
  const editor = useRef(null)
  const [editorState, setEditorState] = useState(null)
  const [uploadInfo, setUploadInfo] = useState({})

  useEffect(() => {
    editor.current.setValue(BraftEditor.createEditorState(initValue))
    getUploadInfo()
  }, [])


  const handleEditorChange = value => {
    setEditorState(value)
    onContentChange(value.toHTML())
  }

  const preview = () => {
    if(editorState !== null) {
      if (window.previewWindow) {
        window.previewWindow.close()
      }
      window.previewWindow = window.open()
      window.previewWindow.document.write(buildPreviewHtml(editorState))
      window.previewWindow.document.close()
    }
    
  }

  const getUploadInfo = () => {
    api.getUploadToken()
      .then(res => {
        if(res.data.code === 0){
          setUploadInfo({
            token: res.data.data.token,
            domain: res.data.data.domain
          }) 
        }
      })
  }

  const blockExportFn = (contentState, block) => {

    const previousBlock = contentState.getBlockBefore(block.key)
  
    if (block.type === 'unstyled' && previousBlock && previousBlock.getType() === 'atomic') {
      return {
        start: '',
        end: '',
      }
    }
  
  }

  const getUploadFileName = () => {
    let filename = {
      name: `${utils.getTimestamp()}${utils.randomWord(6)}`,
      key: 'website/news'
    }
    return filename
  }

  const setFiles = (key, file) => {
    api.getPicPreview({file_keys: file.response.key}).then(res => {
      if(res.data.code === 0) {
        setEditorState(ContentUtils.insertMedias(editorState, [{
          type: 'IMAGE',
          url: res.data.data[0].file_url
        }]))
      }
    })
    
  }

  const extendControls = [
    {
      key: 'custom-button',
      type: 'button',
      text: '预览',
      onClick: preview
    },
    {
      key: 'upload',
      type: 'component',
      component: (
        <Upload
          listType='picture'
          accept='image/*'
          token={uploadInfo.token}
          domain={uploadInfo.domain}
          filename={getUploadFileName}
          setFiles={(key, file) => setFiles(key, file)}
        >
          <button type="button" className="control-item button upload-button" data-title="插入图片">
            插入图片
          </button>
        </Upload>
      )
    },
  ]

  const excludeControls = ['media', 'emoji', 'remove-styles', 'code', 'clear', 'undo', 'redo', 'superscript', 'subscript']

  return (
    <BraftEditor
      ref={editor}
      value={editorState}
      onChange={handleEditorChange}
      extendControls={extendControls}
      excludeControls={excludeControls}
      converts={{blockExportFn}}
    />
  )
}

export default Editor