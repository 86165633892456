import React, {useState, useEffect} from 'react'
import {Modal, Form, DatePicker, Input} from 'antd'
import {utils} from 'dtchain-fe'
import {useLocation} from 'react-router-dom'
import locale from 'antd/es/date-picker/locale/zh_CN'
import * as lodash from 'lodash'
import moment from 'moment'
import 'moment/locale/zh-cn'
import {store} from '../../../index'
import api, {handleResponse} from '../../../api'
import * as actions from '../../../actions/admin_course'


const CourseForm = ({initData, visible, isNew, onCancel=() => {}}) => {
  const location = useLocation()
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if(initData) {
      // antd 4.0 form in modal的情況下，添加延遲，不然會有警告
      setTimeout(() => {
        form.setFieldsValue({
          ...initData,
          time: moment(initData['time'])
        })
      })
    }
    if(!visible) {
      setTimeout(() => {
        form.resetFields()
      })
    }
  }, [visible])

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields()
      const data = {...values}
      if(!data['url']) {
        delete data['url']
      }
      if(initData) {
        data['id'] = initData['id']
      }
      data['time'] = moment(data['time']).format('YYYY-MM')
      setLoading(true)
      const response = await api.publishCourse(data)
      handleResponse({
        response,
        resolve: () => {
          setLoading(false)
          utils.Message('success', `${isNew ? '新增' : '修改'}大事记成功`)
          store.dispatch(actions.getCourseRecords({page: lodash.get(location, 'state.page', 1), loadType: false}))
          onCancel()
        },
        reject: () => setLoading(false)
      })
    } catch (error) {
      setLoading(false)
    }
  }

  return (
    <Modal 
      visible={visible}
      onCancel={onCancel}
      onOk={handleSubmit}
      title='新建大事记'
      okText='确定'
      cancelText='取消'
      maskClosable={false}
      width={650}
      confirmLoading={loading}
      forceRender={true}
    >
      <Form
        form={form} 
        style={{width: '100%'}} 
        labelCol={{span: 4}}
        wrapperCol={{span: 20}}
        name='course'
      >
        <Form.Item
          label='时间:'
          name='time'
          rules={[{required: true, message: '请选择大事记时间'}]}
        >
          <DatePicker
            placeholder='请选择时间'
            picker='month'
            locale={locale}
            onChange={data => form.setFieldsValue({time: data})}
          />
        </Form.Item>
        <Form.Item
          label='内容:'
          name='content'
          rules={[{required: true, message: '请输入大事记内容'}]}
        >
          <Input.TextArea rows={4} style={{'resize': 'none'}} placeholder='请输入'/>
        </Form.Item>
        <Form.Item
          label='跳转url:'
          name='url'
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
    
  )
}

export default CourseForm
