import React from 'react'
import {Link} from 'react-router-dom'
import FadeInBanner from '../../../components/FadeInBanner'
import CSSTransitionByScroll from '../../../components/CSSTransitionByScroll'
import CustomButton from '../../../components/CustomButton'

const BannerBlock = ({setVisible}) => (
  <div className="banner-image image-block">
    <div className="banner-body">
      <div className="content">
        <CSSTransitionByScroll>
          <div className="title">蓝海数链区块链</div>
        </CSSTransitionByScroll>
        <CSSTransitionByScroll delay={100}>
          <div className="description">
            <div>构建“区块链技术+产业服务”生态圈，</div>
            <div>打造企业一站式服务平台</div>
          </div>
        </CSSTransitionByScroll>
        <CSSTransitionByScroll delay={200}>
          <div className="button"><CustomButton value="联系我们" size="large" onClick={setVisible}/></div>
        </CSSTransitionByScroll>
      </div>
      <CSSTransitionByScroll>
        <div className="pic-box"><div className="pic"/></div>
      </CSSTransitionByScroll>
    </div>
  </div>
)

// const BannerFinancial = () => (
//   <div className="banner-image image-financial">
//     <div className="banner-body">
//       <div className="content">
//         <CSSTransitionByScroll>
//           <div className="title">供应链金融</div>
//         </CSSTransitionByScroll>
//         <CSSTransitionByScroll delay={100}>
//           <div className="description">区块链技术赋能供应链金融，降本增效，实现信用穿透</div>
//         </CSSTransitionByScroll>
//         <CSSTransitionByScroll delay={200}>
//           <div className="info-group">
//             <div className="info-group-item">闲置资产增值</div>
//             <div className="info-group-item">银行级安全</div>
//             <div className="info-group-item">高效低成本</div>
//             <div className="info-group-item">灵活可追溯</div>
//           </div>
//         </CSSTransitionByScroll>
//         <CSSTransitionByScroll delay={300}>
//           <div className="button"><Link to="/product/financial"><CustomButton value="了解详情" size="large" /></Link></div>
//         </CSSTransitionByScroll>
//       </div>
//       <CSSTransitionByScroll>
//         <div className="pic-box">
//           <div className="pic">
//             <div className="pic-extra position-1"/>
//             <div className="pic-extra position-2"/>
//             <div className="pic-extra position-3"/>
//             <div className="pic-extra position-4"/>
//           </div>
//         </div>
//       </CSSTransitionByScroll>
//     </div>
//   </div>
// )

const BannerTechnology = () => (
  <div className="banner-image image-technology">
    <div className="banner-body">
      <div className="content">
        <CSSTransitionByScroll>
          <div className="title">技术渗透业务 深挖区块链价值</div>
        </CSSTransitionByScroll>
        <CSSTransitionByScroll delay={100}>
          <div className="description">链接价值，传递信用</div>
        </CSSTransitionByScroll>
        <CSSTransitionByScroll delay={200}>
          <div className="button"><Link to="/technology"><CustomButton value="了解详情" size="large" /></Link></div>
        </CSSTransitionByScroll>
      </div>
      <CSSTransitionByScroll>
        <div className="pic-box"><div className="pic"/></div>
      </CSSTransitionByScroll>
    </div>
  </div>
)

const Banner = ({setVisible}) => (
  <div className="home-banner">
    <FadeInBanner>
      <BannerBlock setVisible={setVisible} />
      {/*<BannerFinancial />*/}
      <BannerTechnology />
    </FadeInBanner>
  </div>
)

export default Banner