import React, {Fragment} from 'react'
import Header from '../../components/Header'
import CSSTransitionByScroll from '../../components/CSSTransitionByScroll'
import icon_bank from '../../style/images/product/financial/icon_bank.png'
import icon_credit from '../../style/images/product/financial/icon_credit.png'
import icon_financing from '../../style/images/product/financial/icon_financing.png'
import icon_retrospective from '../../style/images/product/financial/icon_retrospective.png'
import pic_accounts_receivable from '../../style/images/product/financial/pic_accounts_receivable.png'
import pic_preview_cash from '../../style/images/product/financial/pic_preview_cash.png'
import icon_product from '../../style/images/product/financial/icon_product.png'
import icon_private from '../../style/images/product/financial/icon_private.png'
import icon_custom from '../../style/images/product/financial/icon_custom.png'
import {ProductPlan, ProductBusiness, ProductWorth, ProductCase, ProductAccess} from './components/product'
    
const Banner = () => (
  <div className="product-banner">
    <div className="banner-body">
      <div className="content">
        <CSSTransitionByScroll>
          <div className="title">区块链+供应链金融解决方案</div>
        </CSSTransitionByScroll>
        <CSSTransitionByScroll delay={100}>
          <div className="description">实现对供应链全流程中信息流、资金流的监控管理，解决供应链末端企业融资难题，有助于核心企业稳固供应链生态，增强自身的主导地位。</div>
        </CSSTransitionByScroll>
      </div>
      <CSSTransitionByScroll>
        <div className="pic_gyl"/>
      </CSSTransitionByScroll>
    </div>
  </div>
)

const Financial = () => {
  const planData = [
    {
      icon: icon_bank,
      title: '打造产业银行',
      des: '集团利用闲置资产自建产业银行，赚取供应链中的收益'
    },
    {
      icon: icon_credit,
      title: '信用可信传递',
      des: '核心企业信用沿可信贸易链路传递，大大降低风控成本'
    },
    {
      icon: icon_financing,
      title: '融资低成本',
      des: '中小企业依托核心企业的强信用获得低成本的金融服务'
    },
    {
      icon: icon_retrospective,
      title: '灵活可追溯',
      des: '凭证可随时随地自由拆分、流转、融资，全程可追溯'
    },
  ]

  const planExtra = {
    title: '解决传统供应链金融中几大痛点', 
    list: [
      '中小企业融资难、融资贵',
      '账期长，现金流压力大',
      '银票、商票局限性大',
      '核心企业利润低',
      '信息不透明、不互通',
      '风控成本高、管控难',
    ]
  }

  const worthData = [
    {
      title: '核心企业',
      list: [
        '充分利用闲置资金，自建产业银行，赚取金融收益。',
        '提升商业信用，降低产业链整体成本。',
        '更好的服务链上企业，提高自身议价能力。',
        '降低企业负债率。'
      ]
    },
    {
      title: '中小企业',
      list: [
        '获得可信的核心企业信用背书，低成本的获取金融服务。',
        '缩短账期，提高再生产能力。'
      ]
    },
    {
      title: '金融机构',
      list: [
        '降低风控成本，更好的管控核心企业。',
        '可获得具有公信力的数据。',
        '与核心企业共建双赢生态，增强获客能力。',
        '利用特有回款模式，降低企业违约风险，回款资金快速回笼，实现自由资金加杠杆。'
      ]
    },
  ]

  const accessData = [
    {
      icon: icon_product,
      title: '标准产品',
      des: '公有云部署'
    },
    {
      icon: icon_private,
      title: '标准产品',
      des: '私有化部署'
    },
    {
      icon: icon_custom,
      title: '基于标准产品',
      des: '定制化开发'
    },
  ]

  const caseData = [
    {
      introduce: '应收账款保理融资平台为用户提供了一套完整的供应链服务，包含风控、资产、凭证、供应链企业等各方面管理。极大程度缓解了核心企业管理难、中小企业生存难的痛点问题。多方节点，信息全上链，并实现与公链交叉校验双重保证记录信息真实无篡改，链上交易量已超亿级。',
      // cooperation: <Fragment><img /><img /></Fragment>
    },
    {
      introduce: '供应链开放平台提供基于标准产品的一套标准化接口服务，包含授信、开具、流转、融资、还款、清算等一整套完整功能，适用于期望接入内部管理平台进行相关业务操作的企业。',
      // cooperation: <Fragment><img /></Fragment>
    }
  ]
  return (
    <Fragment>
      <Header theme="light"/>
      <div className="product">
        <Banner />
        
        <ProductPlan planData={planData} planExtra={planExtra}/>
      
        <ProductBusiness tabsTitle={['应收账款融资', '预付款融资']} tabsPane={[
          <img key={1} src={pic_accounts_receivable} />, 
          <img key={2} src={pic_preview_cash} />]} />
      
        <ProductWorth worthData={worthData}/>

        <ProductCase caseData={caseData} tabsTitle={['应收账款保理融资平台', '供应链开放平台']}/>

        <ProductAccess accessData={accessData}/>
      </div>
    </Fragment>
  )
}

export default Financial